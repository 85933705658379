import {createSelector} from 'reselect';
import {appName} from "../config";
import {List, OrderedMap, Record} from 'immutable';
import {all, call, put, select, takeEvery} from '../../node_modules/@redux-saga/core/effects';
import BackendApi from '../BackendApi';
import {backendArrayToOrderedMap} from "../helpers";

export const moduleName = 'servicesAndCategories';
const prefix = `${appName}/${moduleName}`;

export const FETCH_ALL_REQUEST = `${prefix}/FETCH_ALL_REQUEST`;
export const FETCH_ALL_SUCCESS = `${prefix}/FETCH_ALL_SUCCESS`;

const CategoryRecord = Record({
	ID: null,
	NAME: null,
});

const ServicePriceRecord = Record({
	PRICE: null,
	BONUS_REWARD: null,
});

const ServiceRecord = Record({
	ID: null,
	NAME: null,
	TYPE: null,
	CATEGORY_ID: null,
	SORT: null,
	SHORT_DESCRIPTION: null,
	DESCRIPTION: null,
	COMPLEX_DESCRIPTION: null,
	DURATION: null,
	PICTURE: null,
	prices: new OrderedMap(),
	carwashIds: List(),
	recommendedServicesIds: List(),
	innerServicesIds: List(),
});

const FilterRecord = Record({
	ACTIVE_ON_APP: null,
	ACTIVE_ON_SITE: null,
	ACTIVE_ON_TERMINAL: null,
});

const ReducerRecord = Record({
	loading: false,
	loaded: false,
	services: new OrderedMap({}),
	categories: new OrderedMap({}),
	filter: new FilterRecord({ACTIVE_ON_TERMINAL: 1}),
});

export default (state = new ReducerRecord(), action) => {
	const {type, payload} = action;

	switch(type)
	{
		case FETCH_ALL_REQUEST:
			return state.set('loading', true);

		case FETCH_ALL_SUCCESS:
			return state
				.set('loading', false)
				.set('loaded', true)
				.set('services', payload.services.reduce((acc, item) => {
					return acc.set(
						item.ID,
						(new ServiceRecord(item))
							.set('prices', new OrderedMap(item.prices))
							.set('recommendedServicesIds', List(item.recommendedServicesIds))
							.set('innerServicesIds', List(item.innerServicesIds))
					);
				}, new OrderedMap({})))
				.set('categories', backendArrayToOrderedMap(payload.categories, CategoryRecord));

		default:
			return state;
	}
}

/* ------ Selectors ------- */

export const stateSelector = state => state[moduleName];
export const servicesSelector = createSelector(stateSelector, state => state.services.valueSeq().toArray());
export const servicesByCarwashIdSelector = createSelector(servicesSelector, (state, props) => props.carwashId, (services, carwashId) => (
	services.filter((item) => item.carwashIds.includes(carwashId))
));
export const categoriesSelector = createSelector(stateSelector, state => state.categories.valueSeq().toArray());

/* ------ Action Creators ------- */

export function fetchAll(){
	return {
		type: FETCH_ALL_REQUEST
	}
}

/* ------ Sagas -----------------*/

const fetchAllSaga = function* (){
	const {filter} = yield select(stateSelector);

	const backendFilter = filter.toJS();
	for(let key in backendFilter)
	{
		if(backendFilter.hasOwnProperty(key) && backendFilter[key] === null){
			delete backendFilter[key];
		}
	}

	const response = yield call([BackendApi, BackendApi.servicesAndCategories], backendFilter);
	if(!!response.result)
	{
		yield put({
			type: FETCH_ALL_SUCCESS,
			payload: response.result
		})
	}
};

export const saga = function* (){
	yield all([
		takeEvery(FETCH_ALL_REQUEST, fetchAllSaga),
	])
};