import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {moduleName as moduleAuth, signOut, helloConfirm, loadProfile} from '../ducks/auth';
import {moduleName as moduleBackendError, clearBackendError} from '../ducks/backendError';
import {Route} from 'react-router-dom'
import {Link, Switch} from 'react-router-dom';
import '../media/style/style.scss';
import Header from './header/Header';
import SignIn from './auth/SignIn';
import SignSuccess from './auth/SignSuccess';
import Orders from './orders/Orders';
import OrderDetail from './orders/DetailOrder';
import LocalStor from "../localStor";
import Popup from "./common/Popup";
import CreateOrder from "./orders/CreateOrder";
import RevenuePlan from "./common/RevenuePlan";

class Root extends Component {
	static propTypes = {
		// from conect
		auth: PropTypes.object,
		backendError: PropTypes.object,

		// from connect actions
		clearBackendError: PropTypes.func,
	};

	componentDidMount(){
		const {auth, loadProfile} = this.props;
		if(auth.signedIn) this.props.loadProfile();
	}

	render() {
		const {auth, signOut} = this.props;
		return (
			<div>
				<Header signedIn={auth.signedIn && auth.helloConfirmed} signOut={signOut} profile={auth.profile}/>
				<div className="main-wrapper">
					{this.getBody()}
					{this.getBackendError()}
				</div>
			</div>
		)
	}

	getBody() {
		const {auth} = this.props;

		if(!auth.signedIn) return <SignIn/>;
		if(!auth.helloConfirmed) return <SignSuccess buttonClickHandle={this.setHelloConfirmed.bind(this)}/>;
		if(!auth.profile) return;

		return (
			<div>
				<Switch>
					<Route path="/order/create" exact render={({match}) => {
						return <CreateOrder profile={auth.profile}/>
					}}/>
					<Route path="/order/:id" exact render={({match}) => {
						const {id} = match.params;
						return <OrderDetail id = {id} key = {id}/>
					}}/>
				</Switch>
				<RevenuePlan profile={auth.profile}/>
				<Orders profile={auth.profile}/>
			</div>
		)
	}

	getBackendError() {
		const {backendError} = this.props;
		if(!backendError) return null;

		return (
			<Popup type="error" title="Ошибка">
				<p className="acenter">{backendError.error_description}</p>
				<button className="btn btn--yellow w100 mt20" onClick={this.handleClickCloseBackendError}><span>Закрыть</span></button>
			</Popup>
		)
	}

	handleClickCloseBackendError = ev => {
		this.props.clearBackendError();
	};

	setHelloConfirmed() {
		this.props.helloConfirm();
	}
}

export default connect(state => ({
	auth: state[moduleAuth],
	backendError: state[moduleBackendError].error,
}), {signOut, helloConfirm, loadProfile, clearBackendError}, null, {pure: false})(Root)
