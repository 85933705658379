import {appName} from '../config';
import {Record} from 'immutable';
import {all, call, put, select, take, takeEvery} from 'redux-saga/effects';
import {ErrorRecord} from './_common_records';

export const moduleName = 'error';
const prefix = `${appName}/${moduleName}`;

export const SET_BACKEND_ERROR = `${prefix}/SET_BACKEND_ERROR`;
export const CLEAR_BACKEND_ERROR = `${prefix}/CLEAR_BACKEND_ERROR`;

const ReducerRecord = Record({
	error: null,
});

export default (state = new ReducerRecord(), action) => {
	const {type, payload} = action;

	switch(type)
	{
		case SET_BACKEND_ERROR:
			return state
				.set('error', new ErrorRecord(payload));

		case CLEAR_BACKEND_ERROR:
			return state
				.set('error', null);

		default:
			return state;
	}
}

/* ------ Selectors ------- */

export const stateSelector = state => state[moduleName];

/* ------ Action Creators ------- */

export function setBackendError(error){
	return {
		type: SET_BACKEND_ERROR,
		payload: error
	}
}

export function clearBackendError(){
	return {
		type: CLEAR_BACKEND_ERROR
	}
}

/* ------ Sagas -----------------*/