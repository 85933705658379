import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {setBackendError} from '../../ducks/backendError';
import {createMoment, getHMByDate} from '../../helpers';
import BackendApi from "../../BackendApi";
import PopupAddBox from "./common/PupupAddBox";

class OrdersListCalendar extends Component {
	static propTypes = {
		orders: PropTypes.array,
		selectedDate: PropTypes.object.isRequired, // moment required
		carwashId: PropTypes.number.isRequired, // moment required
		carwashBoxNumbers: PropTypes.number.isRequired,
		boxesTimesWork: PropTypes.objectOf(PropTypes.shape({
			from: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired,
		})).isRequired,
		isAdmin: PropTypes.bool,

		handleShowOrder: PropTypes.func.isRequired,
		handleCreateOrder: PropTypes.func.isRequired,
		handleRefreshOrders: PropTypes.func.isRequired,

		// from connect actions
		setBackendError: PropTypes.func
	};

	constructor(props){
		super(props);

		this.state = {
			boxAddLoading: false,
			boxDeleteLoading: false,
			showBoxAddForm: false,
		}
	}


	render(){
		const {selectedDate, isAdmin, carwashId} = this.props;
		const {showBoxAddForm} = this.state;
		const scale = this.getScale();
		const ordersByBoxes = this.getOrdersByBoxes();

		const scaleItems = scale.map(item => {
			return <div className="o-calendar__scale__item" key={item.date.unix()}><span>{item.keyTime}</span></div>
		});

		const now = createMoment();

		const dateStart = selectedDate.clone();
		dateStart.hours(0);
		dateStart.minutes(0);
		dateStart.seconds(0);

		const timeLine = (selectedDate.format('L') === now.format('L')) ? (
			<div className="o-calendar__box__timeline" style={{top: now.hours() * 60 + now.minutes()}}/>
		):null;

		const getDummyElements = boxNumber => {
			const timeWork = this.getBoxTimeWork(boxNumber);

			return scale.map((item) => {
				const isDisabled = item.date.unix() < now.unix();
				let isWorking = true;
				if(timeWork && timeWork.dateFrom && timeWork.dateTo)
				{
					isWorking = item.date.unix() >= timeWork.dateFrom.unix() && item.date.unix() <= timeWork.dateTo.unix();
				}

				return (
					<div
						className={"o-calendar__box__dummy" + (isDisabled || !isWorking ? ' o-calendar__box__dummy--disabled':'') + (!isWorking ? ' o-calendar__box__dummy--not-working':'')}
						key={item.date.unix()}
					>
						<div
							className="o-calendar__box__dummy__inner"
							onClick={this.props.handleCreateOrder(item.date, boxNumber)}
						>
							+ Добавить запись {item.keyTime}
						</div>
					</div>
				)
			})
		};

		const boxes = Object.keys(ordersByBoxes);
		const boxesElements = boxes.map((boxNumber, boxesIndex) => {
			const orders = ordersByBoxes[boxNumber].orders;
			const ordersElements = orders.map((order) => {

				const dateReserveStart = createMoment(order.DATE_START);
				const dateReserveEnd = createMoment(order.DATE_END);

				const isPrevDay = dateReserveStart.unix() < dateStart.unix();

				let durationMinutes = (dateReserveEnd.unix() - dateReserveStart.unix()) / 60;

				if(isPrevDay)
				{
					durationMinutes = durationMinutes - ((dateStart.unix() - dateReserveStart.unix()) / 60);
				}

				const style = {
					top: !isPrevDay ? dateReserveStart.hours() * 60 + dateReserveStart.minutes():0,
					height: durationMinutes, // 30 * Math.round(durationMinutes / 30)
				};

				const className = 'o-calendar__box__item'
					+ (parseInt(durationMinutes / 30) <= 1 ? ' o-calendar__box__item--small':'')
					+ (!order.CONFIRMED ? ' o-calendar__box__item--not-confirmed':'');

				return (
					<div className={className} key={order.ID} style={style} onClick={this.props.handleShowOrder(order.ID)}>
						<div className="o-calendar__box__item__inner">
							<div className="o-calendar__box__item__car-number">{order.CAR_NUMBER}</div>
							<div className="o-calendar__box__item__car-mark">{order.CAR_MARK} {order.CAR_MODEL}</div>
							<div className="o-calendar__box__item__times">{getHMByDate(dateReserveStart)} - {getHMByDate(dateReserveEnd)}</div>
							{order.FROM === 'app' ? <div className="o-calendar__box__item__point"/>:''}
						</div>
					</div>
				)
			});

			return (
				<div className="o-calendar__box" key={boxNumber}>
					{isAdmin && boxesIndex === (boxes.length - 1) && !ordersElements.length && (
						<button
							className="o-calendar__box__delete"
							onClick={this.handleBoxDown(carwashId, selectedDate)}
							disabled={this.state.boxDeleteLoading}
						>{this.state.boxDeleteLoading ? 'Подождите...':'Удалить бокс'}</button>
					)}
					<div className="o-calendar__box__title">{boxNumber} бокс</div>
					<div className="o-calendar__box__items">
						{getDummyElements(boxNumber)}
						{ordersElements}
						{timeLine}
					</div>
				</div>
			);
		});

		const addBox = isAdmin ? (
			<div className="o-calendar__box">
				<div className="o-calendar__box__title"/>
				<div className="o-calendar__box__add">
					<button
						onClick={() => this.setState({showBoxAddForm: true})}
						disabled={this.state.boxAddLoading}
					>{this.state.boxAddLoading ? 'Подождите...':'Добавить бокс'}</button>
				</div>
			</div>
		):null;

		return (
			<>
				<div className="o-calendar">
					<div className="o-calendar__scale">
						{scaleItems}
					</div>
					<div className="o-calendar__boxes">
						{boxesElements}
						{addBox}
					</div>
				</div>
				{!!showBoxAddForm && (
					<PopupAddBox
						onClickCancel={() => this.setState({showBoxAddForm: false})}
						carwashId={carwashId}
						selectedDate={selectedDate}
						onSuccess={() => {
							this.props.handleRefreshOrders();
							this.setState({showBoxAddForm: false});
						}}
					/>
				)}
			</>
		)
	}

	/**
	 * Формирует массив часы:минуты для шкалы.
	 *
	 * @return {array}
	 */
	getScale(){
		const scale = [];

		const {selectedDate} = this.props;

		let minTime = createMoment(selectedDate);
		minTime.hours(0);
		minTime.minutes(0);

		let maxTime = createMoment(selectedDate);
		maxTime.hours(23);
		maxTime.minutes(59);

		while(maxTime - minTime >= 0)
		{
			scale.push({
				keyTime: getHMByDate(minTime),
				date: minTime.clone(),
			});
			minTime.add((30 * 60), 'seconds');
		}

		return scale;
	};

	/**
	 * Формирует массив часы:минуты для шакалы. Диапазон беретеся из минимальной и максимальной даты брони.
	 *
	 * @return {array}
	 */

	/*getAdaptiveScale(){
		// То что ниже, сейчас не очень жизнеспособно из-за проблем с timezone. Нужно использовать momentjs.
		// Поэтому пока решил отключить этот функционал и доработать потом как время будет. И вообще не стояло такой задачи

		// MH_DOWORK1 Переделать метод, чтобы он нормально адаптировал шкалу. С учётом текущего дня, будущего дня и заявок.
		// 1. Если заявки за текущий день, то начало шкалы начинается с текущего времени но не выше первой заявки
		// 2. Если заявки за прошедший день, то начало шкалы начинается с первой заявки
		// 3. Если заявки за будущий день, то начало шкалы начинается с 0:00

		const scale = [];

		const {orders} = this.props;

		const nowTime = new Date(Date.now());
		nowTime.setUTCHours(nowTime.getUTCHours() + 7); // пофиксить это безобразие.

		let minTime = new Date(!!orders[0] ? orders[0].dateReserve:this.props.selectedDate);
		if(!orders[0])
		{
			minTime.setUTCHours(0);
			minTime.setUTCMinutes(0);
		}
		else if(minTime.getDate() === nowTime.getDate())
		{
			minTime.setUTCHours(nowTime.getMinutes() > 30 ? (nowTime.getHours() + 1):nowTime.getHours());
			minTime.setUTCMinutes(0);
		}

		let maxTime = new Date(!!orders[0] ? orders[0].dateReserve:this.props.selectedDate);
		maxTime.setUTCHours(23);
		maxTime.setUTCMinutes(59);

		if(orders)
		{
			for(let i = 0; i < orders.length; ++i)
			{
				let order = orders[i];
				let dateReserveStart = new Date(order.dateReserve);
				let dateReserveEnd = new Date(order.dateReserve);
				dateReserveEnd.setTime(dateReserveEnd.getTime() + (parseInt(order.reserveDurationMinutes) * 60 * 1000));

				minTime = new Date(Math.min(dateReserveStart.getTime(), minTime.getTime()));
				maxTime = new Date(Math.max(dateReserveEnd.getTime(), maxTime.getTime()));
			}
		}

		while(maxTime - minTime >= 0)
		{
			scale.push({
				keyTime: getHMByDate(minTime),
				date: new Date(minTime)
			});
			minTime.setTime(minTime.getTime() + (30 * 60 * 1000));
		}

		return scale;
	}*/

	getBoxTimeWork(boxNumber){
		const {boxesTimesWork, selectedDate} = this.props;
		const timeWork = boxesTimesWork[`box${boxNumber}`];

		if(timeWork && timeWork.from && timeWork.to)
		{
			timeWork.dateFrom = createMoment(`${selectedDate.format('YYYY-MM-DD')} ${timeWork.from}`);
			timeWork.dateTo = createMoment(`${selectedDate.format('YYYY-MM-DD')} ${timeWork.to}`);
		}

		return timeWork;
	}

	/**
	 * Формирует массив броней по боксам + заполняет пустые получасовые промежутки
	 * @return {Object}
	 */
	getOrdersByBoxes(){
		let ordersByBoxes = {};

		const {orders, carwashBoxNumbers} = this.props;

		let maxBoxNumber = carwashBoxNumbers;

		if(orders.length)
		{
			maxBoxNumber = orders.reduce((acc, order) => {
				acc = Math.max(acc, order.BOX_NUMBER);
				return acc;
			}, maxBoxNumber);
		}

		for(let i = 1; i <= maxBoxNumber; ++i)
		{
			if(!ordersByBoxes[i])
			{
				ordersByBoxes[i] = {
					boxNumber: i,
					orders: []
				};
			}
		}

		if(orders.length)
		{
			for(let i = 0; i < orders.length; ++i)
			{
				const order = orders[i];
				const {BOX_NUMBER} = order;

				if(!ordersByBoxes[BOX_NUMBER])
				{
					ordersByBoxes[BOX_NUMBER] = {
						boxNumber: BOX_NUMBER,
						orders: []
					};
				}

				ordersByBoxes[BOX_NUMBER].orders.push(order);
			}
		}

		return ordersByBoxes;
	}

	/**
	 * @param {number} carwashId
	 * @param {moment.Moment} date
	 */
	handleBoxUp = (carwashId, date) =>  ev => {
		const request = BackendApi.carwshBoxUp(carwashId, date.format('DD.MM.YYYY'));

		this.setState({
			boxAddLoading: true
		});

		request.then(response => {
			if(!!response.result)
			{
				this.props.handleRefreshOrders();
			}
			else if(!!response.error)
			{
				this.props.setBackendError(response);
			}

			this.setState({
				boxAddLoading: false
			});
		})
	};

	/**
	 * @param {number} carwashId
	 * @param {moment.Moment} date
	 */
	handleBoxDown = (carwashId, date) =>  ev => {
		const request = BackendApi.carwshBoxDown(carwashId, date.format('DD.MM.YYYY'));

		this.setState({
			boxDeleteLoading: true
		});

		request.then(response => {
			if(!!response.result)
			{
				this.props.handleRefreshOrders();
			}
			else if(!!response.error)
			{
				this.props.setBackendError(response);
			}

			this.setState({
				boxDeleteLoading: false
			});
		})
	};
}

export default connect(null, {setBackendError})(OrdersListCalendar);
