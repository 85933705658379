import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
	categoriesSelector,
	fetchAll,
	moduleName as servicesAndCategoriesModule,
	servicesByCarwashIdSelector,
} from '../../../ducks/servicesAndCategories';
import {displayDuration} from '../../../helpers';

class ServiceCardsSelect extends React.Component {
	static propTypes = {
		carwashId: PropTypes.number,
		carCategoryId: PropTypes.number,
		initSelectedIds: PropTypes.array,
		onSelect: PropTypes.func,

		// from connect
		services: PropTypes.array,
		categories: PropTypes.array,
		loading: PropTypes.bool,
		loaded: PropTypes.bool,

		// from connect actions
		fetchAll: PropTypes.func
	};

	constructor(props){
		super(props);

		this.state = {
			selectedIds: props.initSelectedIds && !!props.initSelectedIds.slice ? props.initSelectedIds.slice():[],
		}
	}

	componentWillReceiveProps(nextProps){
		if(nextProps.initSelectedIds && nextProps.initSelectedIds.slice && nextProps.initSelectedIds !== this.props.initSelectedIds)
		{
			this.setState({
				selectedIds: nextProps.initSelectedIds.slice()
			})
		}
	}

	componentDidMount(){
		if(!this.props.loaded) this.props.fetchAll();
	}

	render(){
		const {loaded, loading, categories, carCategoryId} = this.props;
		const {selectedIds} = this.state;

		if(loading && !loaded) return <div style={{paddingBottom: '15px'}}>Загрузка...</div>;
		if(!loaded) return null;
		if(!this.props.services || !categories) return null;

		const filterServicesByCategoryId = (categoryId, services) => services.filter(item => parseInt(item.CATEGORY_ID) === parseInt(categoryId));

		return (
			<div>
				{categories.map(category => {
					const services = filterServicesByCategoryId(category.ID, this.props.services);
					if(!services.length) return null;
					return (
						<div className="o-service-group" key={category.ID}>
							<div className="o-service-group__title">{category.NAME}</div>
							{services.map(service => {
								const price = service.prices.get(String(carCategoryId));
								const selected = selectedIds.indexOf(service.ID) !== -1;
								return (
									<div className={"o-service-card" + (selected ? ' o-service-card--selected':'')} key={service.ID} onClick={this.handleServiceChecked(service.ID)}>
										<div className="o-service-card__title bold">
											{service.NAME}
										</div>
										<span className="o-service-card__time">{displayDuration(parseInt(service.DURATION))}</span>
										<span className="o-service-card__price">{price ? `${price.PRICE} ₽`:'нет цены'}</span>
									</div>
								)
							})}
						</div>
					)
				})}
			</div>
		);
	}

	handleServiceChecked = id => ev => {
		const {onSelect} = this.props;
		const selectedIds = this.state.selectedIds.slice();

		const index = selectedIds.indexOf(id);
		if(index === -1) selectedIds.push(id);
		else selectedIds.splice(index, 1);

		if(typeof onSelect === 'function') onSelect(selectedIds);
		this.setState({selectedIds});
	}
}

export default connect((state, ownProps) => ({
	services: servicesByCarwashIdSelector(state, ownProps),
	categories: categoriesSelector(state),
	loading: state[servicesAndCategoriesModule].loading,
	loaded: state[servicesAndCategoriesModule].loaded,
}), {fetchAll})(ServiceCardsSelect);