import React from 'react';
import PropTypes from 'prop-types';

const IconTimeStatus = props => {
	const statusToClassName = {
		wait: 'points',
		executing: 'car',
		success: 'success',
	};
	if(!statusToClassName[props.timeStatus]) return null;
	return (
		<span className={'o-wash-status-icon o-wash-status-icon--' + statusToClassName[props.timeStatus]}/>
	);
};

IconTimeStatus.propTypes = {
	timeStatus: PropTypes.string
};

export default IconTimeStatus;