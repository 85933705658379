const ACCESS_TOKEN = 'access_token';
const CURRENT_PROFILE = 'current_pofile';
const HELLO_CONFIRMED = 'hello_confirmed';
const ORDER_VIEW_TYPE = 'order_view_type';

class LocalStor {
	static setAccessToken(value){
		this.set(ACCESS_TOKEN, value);
	}
	static getAccessToken(){
		return this.get(ACCESS_TOKEN);
	}

	static setCurrentProfile(value){
		this.set(CURRENT_PROFILE, JSON.stringify(value));
	}

	static getCurrentProfile(){
		try
		{
			return JSON.parse(this.get(CURRENT_PROFILE));
		}
		catch(e)
		{
			return null;
		}
	}

	static setHelloConfirmed(value){
		this.set(HELLO_CONFIRMED, value);
	}
	static getHelloConfirmed(){
		return this.get(HELLO_CONFIRMED);
	}

	static setOrderViewType(value){
		this.set(ORDER_VIEW_TYPE, value);
	}
	static getOrderViewType(){
		return this.get(ORDER_VIEW_TYPE);
	}

	static set(name, value) {
		localStorage.setItem(name, value);
	}

	static get(name) {
		return localStorage.getItem(name);
	}
}

export default LocalStor;