import React, {Component} from 'react';
import {connect} from 'react-redux'
import {moduleName as moduleAuth, signIn} from "../../ducks/auth";

let timerWait = null;

class SignIn extends Component {
	state = {
		login: '',
		password: '',
		submited: false
	};

	render(){
		const {loading, signedIn} = this.props;
		if(signedIn) return null;

		return (
			<div className="popup-wrap">
				<div className="popup">
					<h2 className="popup__title popup__title--head">Вход</h2>
					<form className="signin-form" onSubmit={this.handleSubmit}>
						<div className="signin-form__inp-group">
							<div className={'signin-form__inp ' + this.getInputClassName('login')}>
								<label>* Логин</label>
								<input value={this.state.login} onChange={this.handleChange('login')}/>
							</div>
							<div className={'signin-form__inp ' + this.getInputClassName('password')}>
								<label>* Пароль</label>
								<input value={this.state.password} type="password" onChange={this.handleChange('password')} placeholder="*********"/>
							</div>
						</div>
						<div className="signin-form__button-wrap">
							<button className="signin-form__button" type="submit" disabled={this.buttonIsDisabled()}>{loading ? 'Подождите':'Войти'}</button>
						</div>
					</form>
				</div>
			</div>
		)
	}

	handleSubmit = ev => {
		ev.preventDefault();
		const {login, password} = this.state;
		let submited = false;

		if(login.length && password.length)
		{
			this.props.signIn(login, password);
			submited = true;
		}

		this.setState({
			submited: submited
		});
	};

	handleChange = type => ev => {
		const {value} = ev.target;

		this.setState({
			[type]: value,
			submited: false
		})
	};

	getInputClassName = type => {
		if(this.state.submited && this.props.error)
		{
			return 'signin-form__inp--error';
		}
		if(this.isSuccessField(type))
		{
			return 'signin-form__inp--success';
		}
	};

	isSuccessField(type) {
		return this.state[type].length >= limits[type].min && this.state[type].length <= limits[type].max;
	}

	buttonIsDisabled() {
		const {loading, error} = this.props;
		if(
			loading
			|| (this.state.submited && error)
			|| !this.isSuccessField('login')
			|| !this.isSuccessField('password')
		) return true;
		return false;
	}
}

const limits = {
	login: {
		min: 5,
		max: 50
	},
	password: {
		min: 5,
		max: 50
	}
};

export default connect(state => ({
	signedIn: state[moduleAuth].signedIn,
	loading: state[moduleAuth].signInLoading,
	error: state[moduleAuth].signInError,
}), {signIn})(SignIn)