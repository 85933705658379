import {Record, OrderedMap, List} from "immutable";
import {backendArrayToOrderedMap} from '../helpers';

export const OrderCarwashRecord = Record({
	ID: null,
	NAME: null,
	ADDRESS: null,
	PHOTO: null,
	REAL_BOX_NUMBERS: null,
});

export const OrderCouponRecord = Record({
	ID: null,
	NAME: null,
	CODE: null
});

export const OrderItemRecord = Record({
	ID: null,
	PRICE: null,
	DISCOUNT_PRICE: null,
	DURATION: null,
	SERVICE_ID: null,
	SERVICE_NAME: null,
	SERVICE_TYPE: null,
	SERVICE_CATEGORY_ID: null,
	SERVICE_SHORT_DESCRIPTION: null,
	SERVICE_COMPLEX_DESCRIPTION: null,
	SERVICE_PICTURE: null,
});

export const OrderRecord = Record({
	ID: null,
	CARWASH_ID: null,
	BOX_NUMBER: null,
	FROM: null,
	DATE_START: null,
	DATE_END: null,
	PRICE: null,
	DISCOUNT_PRICE: null,
	PAY_PRICE: null,
	USED_BONUS_BALANCE: null,
	BONUS_REWARD: null,
	PAID: null,
	CONFIRMED: null,
	COUPON_ID: null,
	DURATION: null,
	CUSTOM_DURATION: null,
	CLIENT_ID: null,
	CLIENT_NAME: null,
	CLIENT_PHONE: null,
	CAR_ID: null,
	CAR_CATEGORY_ID: null,
	CAR_NUMBER: null,
	CAR_MARK: null,
	CAR_MODEL: null,
	CLIENT_COMMENT: null,
	MANAGER_COMMENT: null,
	DATE_UPDATE: null,
	DATE_CREATE: null,
	CARWASH: new OrderCarwashRecord(),
	COUPON: new OrderCouponRecord(),
	ITEMS: new OrderedMap(),
	MORE_TIME_NEED:	null,
	MORE_TIME_NEED_DISPLAY: null,

	serviceIds: List(), // for create order
});

export const ErrorRecord = Record({
	error: null,
	error_description: null,
	argument: null,
});

export const SuccessRecord = Record({
	title: null,
	subtitle: null,
	message: null
});

export function backendOrderToRecord(order){
	let orderRecord = (new OrderRecord(order))
		.set('CARWASH', new OrderCarwashRecord(order.CARWASH))
		.set('COUPON', new OrderCouponRecord(order.COUPON))
		.set('ITEMS', backendArrayToOrderedMap(order.ITEMS, OrderItemRecord));

	if(order.ITEMS && order.ITEMS.slice)
	{
		orderRecord = orderRecord.set('serviceIds', List(order.ITEMS.map(item => item.SERVICE_ID)));
	}

	return orderRecord;
}