import {Map, OrderedMap} from 'immutable';
import moment from 'moment';

export function backendArrayToOrderedMap(arr, RecordModel = Map){
	return arr.reduce((acc, item, index) =>
			acc.set(item.ID !== null ? item.ID:index, new RecordModel(item))
		, new OrderedMap({}))
}

export function isNullOrUndefined(value){
	return (typeof value === "undefined") || value === null;
}

export function toString(value){
	return !isNullOrUndefined(value) ? String(value):'';
}

export function toInt(value){
	value = parseInt(value);
	return !isNaN(value) ? value:0;
}

/**
 * @param {string} [date]
 * @return {moment.Moment}
 */
export function createMoment(date){
	return moment(date).utcOffset(7);
}

/**
 * Возвращает час:минута
 *
 * @param {moment.Moment} date
 * @return {string}
 */
export function getHMByDate(date) {
	return date.format('HH[:]mm');
}

/**
 * Возвращает статус по диапазону дат. Ожидаение, выполняется, выполнено
 *
 * @param {moment.Moment} dateStart
 * @param {moment.Moment} dateEnd
 * @param {moment.Moment} [currentDate]
 * @return {string}
 */
export function calculateTimeStatus(dateStart, dateEnd, currentDate){
	currentDate = currentDate ? currentDate:createMoment();

	if(currentDate < dateStart)
		return 'wait';
	if(currentDate >= dateStart && currentDate <= dateEnd)
		return 'executing';
	return 'success';
}

/**
 * @param {number} duration - секунды
 * @return string
 */
export function displayDuration(duration){
	if(!isFinite(duration) || isNaN(duration)) return '';

	duration = duration / 60; // Перевод в минуты

	const hours = parseInt(duration/60);
	const minutes = Math.ceil(duration - hours * 60);

	return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}