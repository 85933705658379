import {createSelector} from 'reselect';
import {appName} from "../config";
import {OrderedMap, Record} from 'immutable';
import {all, call, put, select, takeEvery} from '../../node_modules/@redux-saga/core/effects';
import {backendArrayToOrderedMap, toString} from '../helpers';
import moment from 'moment';
import BackendApi from '../BackendApi';
import LocalStor from "../localStor";
import {SET_FIELD as SET_FIELD_CREATE_ORDER} from "./createOrder";

export const moduleName = 'orders';
const prefix = `${appName}/${moduleName}`;

export const FETCH_ALL_REQUEST = `${prefix}/FETCH_ALL_REQUEST`;
export const FETCH_ALL_SUCCESS = `${prefix}/FETCH_ALL_SUCCESS`;
export const CLEAR_ENTITIES = `${prefix}/CLEAR_ENTITIES`;
export const FILTER_SET = `${prefix}/FILTER_SET`;
export const FILTER_FIELD_CHANGE = `${prefix}/FILTER_FIELD_CHANGE`;
export const VIEW_TYPE_CHANGE = `${prefix}/VIEW_TYPE_CHANGE`;
export const SET_LOADING = `${prefix}/SET_LOADING`;

export const CALENDAR_VIEW = 'calendar';
export const TABLE_VIEW = 'table';

const OrderRecord = Record({
	ID: null,
	CARWASH_ID: null,
	CARWASH_ADDRESS: null,
	DATE_START: null,
	DATE_END: null,
	BOX_NUMBER: null,
	FROM: null,
	CLIENT_NAME: null,
	CLIENT_PHONE: null,
	CAR_NUMBER: null,
	CAR_MARK: null,
	CAR_MODEL: null,
	PRICE: null,
	DISCOUNT_PRICE: null,
	PAY_PRICE: null,
	PAID: null,
	CONFIRMED: null,
	DATE_CREATE: null,
	SERVICES: [],
});

const FilterRecord = Record({
	CARWASH_ID: null,
	DATE_START_FROM: null,
	DATE_START_TO: null,
	DATE_START: null,
	SEARCH: null,
});

const ReducerRecord = Record({
	loading: false,
	loaded: false,
	carwashBoxNumbers: 0,
	boxesTimesWork: {},
	filter: new FilterRecord(),
	viewType: LocalStor.getOrderViewType() || CALENDAR_VIEW,
	entities: new OrderedMap({})
});

export default (state = new ReducerRecord(), action) => {
	const {type, payload} = action;

	switch(type)
	{
		case FETCH_ALL_REQUEST:
			return state.set('loading', true);

		case FETCH_ALL_SUCCESS:
			return state
				.set('loading', false)
				.set('loaded', true)
				.set('carwashBoxNumbers', payload.carwashBoxNumbers)
				.set('boxesTimesWork', payload.boxesTimesWork || {})
				.set('entities', backendArrayToOrderedMap(payload.orders, OrderRecord));

		case FILTER_FIELD_CHANGE:
			let value = payload.value;
			switch(payload.field)
			{
				case 'DATE_START_FROM':
				case 'DATE_START_TO':
				case 'DATE_START':
					value = moment(value);
					break;
			}
			return state.setIn(['filter', payload.field], value);

		case FILTER_SET:
			return state.set('filter', new FilterRecord(payload));

		case CLEAR_ENTITIES:
			return state
				.set('loaded', false)
				.set('entities', OrderedMap({}));

		case VIEW_TYPE_CHANGE:
			return state.set('viewType', payload);

		case SET_LOADING:
			return state.set('loading', true);

		default:
			return state;
	}
}

/* ------ Selectors ------- */

export const stateSelector = state => state[moduleName];
export const entitiesSelector = createSelector(stateSelector, state => state.entities);
export const ordersSelector = createSelector(entitiesSelector, entities => (
	entities.valueSeq().toArray()
));
export const siteOrdersCountSelector = createSelector(entitiesSelector, entities => {
	const count = entities.countBy((iter, key, value) => iter.FROM === 'site').toJS()['true'];
	return !isNaN(count) ? count:0;
});

/* ------ Action Creators ------- */

export function fetchAll(){
	return {
		type: FETCH_ALL_REQUEST
	}
}

export function clearEntities(){
	return {
		type: CLEAR_ENTITIES
	}
}

export function viewTypeChange(type){
	return {
		type: VIEW_TYPE_CHANGE,
		payload: type
	}
}

export function setLoading(type){
	return {
		type: SET_LOADING
	}
}

export function filterFieldChange(field, value){
	return {
		type: FILTER_FIELD_CHANGE,
		payload: {field, value}
	}
}

export function filterSet(filter){
	return {
		type: FILTER_SET,
		payload: filter
	}
}

/* ------ Sagas -----------------*/

const fetchAllSaga = function* (){
	const {filter, viewType} = yield select(stateSelector);

	const backendFilter = {
		CARWASH_ID: filter.CARWASH_ID,
		SEARCH: filter.SEARCH,
	};

	switch(viewType)
	{
		case TABLE_VIEW:
			backendFilter.DATE_START_FROM = filter.DATE_START_FROM ? filter.DATE_START_FROM.format('YYYY-MM-DD'):null;
			backendFilter.DATE_START_TO = filter.DATE_START_TO ? filter.DATE_START_TO.format('YYYY-MM-DD'):null;
			break;

		case CALENDAR_VIEW:
			backendFilter.DATE_START = filter.DATE_START ? filter.DATE_START.format('YYYY-MM-DD'):null;
			break;
	}

	const {result} = yield call([BackendApi, BackendApi.orderListWithCarwashBoxNumbers], backendFilter);
	yield put({
		type: FETCH_ALL_SUCCESS,
		payload: {
			orders: result['list']['result'] || [],
			carwashBoxNumbers: result['carwash_box_numbers']['result']['boxNumbers'] || 0,
			boxesTimesWork: result['carwash_box_numbers']['result']['timesWork'] || {},
		}
	})
};

const viewTypeChangeSaga = function* (action){
	yield call([LocalStor, LocalStor.setOrderViewType], action.payload);
};

const filterFieldChangeSaga = function* (action){

	const {filter} = yield select(stateSelector);
	const {field, value} = action.payload;

	if(field)
	{
		const isLength = toString(value).length;

		switch(field)
		{
			case 'SEARCH':
				if(isLength)
				{
					yield put({
						type: VIEW_TYPE_CHANGE,
						payload: TABLE_VIEW
					})
				}
				break;

			// Для нового заказа устанавливаем ID автомойки
			case 'CARWASH_ID':
				yield put({
					type: SET_FIELD_CREATE_ORDER,
					payload: {field, value}
				});
				break;
		}
	}
};

const filterSetSaga = function* (action){
	const {payload} = action;
	if(payload)
	{
		if(payload.hasOwnProperty('SEARCH') && toString(payload.SEARCH).length)
		{
			yield put({
				type: VIEW_TYPE_CHANGE,
				payload: TABLE_VIEW
			})
		}
	}
};

export const saga = function* (){
	yield all([
		takeEvery(FETCH_ALL_REQUEST, fetchAllSaga),
		takeEvery(VIEW_TYPE_CHANGE, viewTypeChangeSaga),
		takeEvery(FILTER_FIELD_CHANGE, filterFieldChangeSaga),
		takeEvery(FILTER_SET, filterSetSaga),
	])
};
