import {createSelector} from 'reselect';
import {appName} from "../config";
import {OrderedMap, Record} from 'immutable';
import {all, call, put, select, takeEvery} from '../../node_modules/@redux-saga/core/effects';
import {backendArrayToOrderedMap} from '../helpers';
import BackendApi from '../BackendApi';

export const moduleName = 'carCategories';
const prefix = `${appName}/${moduleName}`;

export const FETCH_ALL_REQUEST = `${prefix}/FETCH_ALL_REQUEST`;
export const FETCH_ALL_SUCCESS = `${prefix}/FETCH_ALL_SUCCESS`;

const CarCategory = Record({
	ID: null,
	NAME: null,
	PICTURE: null,
});

const ReducerRecord = Record({
	loading: false,
	loaded: false,
	entities: new OrderedMap({})
});

export default (state = new ReducerRecord(), action) => {
	const {type, payload} = action;

	switch(type)
	{
		case FETCH_ALL_REQUEST:
			return state.set('loading', true);

		case FETCH_ALL_SUCCESS:
			return state
				.set('loading', false)
				.set('loaded', true)
				.set('entities', backendArrayToOrderedMap(payload, CarCategory));

		default:
			return state;
	}
}

/* ------ Selectors ------- */

export const stateSelector = state => state[moduleName];
export const entitiesSelector = createSelector(stateSelector, state => state.entities);
export const carCategoriesSelector = createSelector(entitiesSelector, entities => (
	entities.valueSeq().toArray()
));

/* ------ Action Creators ------- */

export function fetchAll(){
	return {
		type: FETCH_ALL_REQUEST
	}
}

/* ------ Sagas -----------------*/

const fetchAllSaga = function* (){
	const {filter, viewType} = yield select(stateSelector);

	const {result} = yield call([BackendApi, BackendApi.carCategories]);
	yield put({
		type: FETCH_ALL_SUCCESS,
		payload: result
	})
};

export const saga = function* (){
	yield all([
		takeEvery(FETCH_ALL_REQUEST, fetchAllSaga),
	])
};