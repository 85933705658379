import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logo from '../../media/img/logo.svg';
import Link from "react-router-dom/es/Link";
import SearchOrders from "./SearchOrders";

class Header extends Component {
	static propTypes = {
		signedIn: PropTypes.bool,
		profile: PropTypes.object,
		signOut: PropTypes.func
	};

	static contextTypes = {
		router: PropTypes.shape({
			history: PropTypes.shape({
				push: PropTypes.func.isRequired,
				replace: PropTypes.func.isRequired,
				createHref: PropTypes.func.isRequired
			}).isRequired
		}).isRequired
	};

	render() {
		return (
			<div className="header">
				<div className="header__inner">
					{this.props.signedIn && <SearchOrders/>}
					<div className="header__inner__center">
						<div className="header__logo">
							<img src={logo} alt="" onClick={event => this.context.router.history.push('/')}/>
						</div>
					</div>
					{this.getButtons()}
				</div>
			</div>
		)
	}

	getButtons() {
		const {signedIn, profile} = this.props;
		if(!signedIn) return null;

		return (
			<div className="header__inner__right">
				<div className="header__btn__user-btns">
					{profile ? (
						<div>
							<button className="header__btn header__btn--user">
								<span>{profile.NAME}</span>
							</button>
							<div className="header__btn-logout">
								<button onClick={this.signOut} className="header__btn header__btn--logout">
									<span>Выйти</span>
								</button>
							</div>
						</div>
					):null}
				</div>
			</div>
		)
	}

	signOut = ev => {
		ev.preventDefault();
		this.props.signOut();
	}
}

export default Header;