import React, {Component} from 'react';
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/ru';
import PropTypes from "prop-types";
import ExampleCalendar from './ExampleCalendar';

class CalendarButton extends Component {
	static propTypes = {
		selectedDay: PropTypes.object,
		buttonExtClassName: PropTypes.string,
		handleDayChange: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
			selectedDay: props.selectedDay || null
		};
	}

	handleDayChange(selectedDay, modifiers, dayPickerInput) {
		this.setState({
			selectedDay
		});

		if(typeof this.props.handleDayChange === "function")
		{
			this.props.handleDayChange(selectedDay, modifiers, dayPickerInput);
		}
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.selectedDay !== nextProps.selectedDay)
		{
			this.setState({
				selectedDay: nextProps.selectedDay,
			});
		}
	}

	render(){
		// MH_DOWORK1 Пофиксить ошибку в консоли Check the render method of `DayPickerInput`.
		return (
			<DayPickerInput
				dayPickerProps={{
					localeUtils: MomentLocaleUtils,
					locale: 'ru',
					showOutsideDays: true,
					selectedDays: this.state.selectedDay
				}}
				onDayChange={this.handleDayChange.bind(this)}
				component={(props) => {
					const {selectedDay} = this.state;
					return (
						<button {...props} className={'btn btn--icon-calendar ' + this.props.buttonExtClassName}>
							<span>{selectedDay ? selectedDay.toLocaleString('ru', {year: 'numeric', month: 'short', day: 'numeric',}):'Выбрать дату'}</span>
						</button>
					)
				}}
			/>
		);
	}
}

export default CalendarButton;