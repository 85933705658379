import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DateTimeSelect from "./DateTimeSelect";
import {createMoment} from "../../../helpers";

class AsidePopupDateTimeSelect extends Component {
	static propTypes = {
		isOpen: PropTypes.bool,
		onSelect: PropTypes.func,
		handleClose: PropTypes.func,

		order: PropTypes.object.isRequired,
	};

	constructor(props){
		super(props);

		this.state = {
			selectedDateStart: null,
			selectedDateEnd: null,
			selectedBoxnumber: null,
		};
	}

	render(){
		const {isOpen, handleClose, ...rest} = this.props;
		const {selectedDateStart, selectedDateEnd, selectedBoxnumber} = this.state;

		if(!isOpen) return null;
		if(!this.props) return null;

		const dateStart = createMoment(selectedDateStart);
		const dateEnd = createMoment(selectedDateEnd);

		const canSelect = dateStart.isValid() && parseInt(selectedBoxnumber) > 0;
		return (
			<div className="aside-popup aside-popup--narrow aside-popup--light-dark">
				<div className="aside-popup__head aside-popup__head--white">
					<h2>Дата/Время</h2>
					<div className="aside-popup__head__close" onClick={handleClose}>закрыть</div>
				</div>
				<div className="aside-popup__body">
					<DateTimeSelect {...rest} onSelect={this.handleDateStartSelect}/>
				</div>
				<div className="aside-popup__foot">
					<button className="btn btn--yellow w100" disabled={!canSelect} onClick={this.handleSelectButtonClick}>
						<span>
							Выбрать {canSelect ? `${dateStart.format('HH:mm')} - ${dateEnd.format('HH:mm')}, ${selectedBoxnumber} бокс`:''}
						</span>
					</button>
				</div>
			</div>
		);
	}

	/**
	 * @param {moment.Moment} dateStart
	 * @param {moment.Moment} dateEnd
	 * @param {string|number} boxNumber
	 */
	handleDateStartSelect = (dateStart, dateEnd, boxNumber) => {
		this.setState({
			selectedDateStart: dateStart,
			selectedDateEnd: dateEnd,
			selectedBoxnumber: boxNumber,
		});
	};

	handleSelectButtonClick = ev => {
		const {handleClose, onSelect} = this.props;
		if(typeof onSelect === 'function')
		{
			onSelect(this.state.selectedDateStart, this.state.selectedDateEnd, this.state.selectedBoxnumber);
		}
		if(handleClose) handleClose();
	}
}

export default AsidePopupDateTimeSelect;