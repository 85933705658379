import React from 'react';
import PropTypes from 'prop-types';
import {fromNames, paidNames} from "../../../config";

const StatusLables = props => {
	const {confirmed, paid, from} = props;
	return (
		[
			<span key="from" className={"label " + (!confirmed ? 'label--yellow':'')}>{fromNames[from]}</span>,
			<span key="paid" className={"label " + (paid === true ? 'label--green':'label--yellow')}>{paidNames[paid === true ? 'Y':'N']}</span>
		]
	);
};

StatusLables.propTypes = {
	from: PropTypes.string,
	paid: PropTypes.bool,
	confirmed: PropTypes.bool
};

export default StatusLables;