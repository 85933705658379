import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {carCategoriesSelector, fetchAll, moduleName as carCategoriesModule} from '../../../ducks/carCategories';

class CarCategoryCardsSelect extends React.Component {
	static propTypes = {
		selectedId: PropTypes.number,
		className: PropTypes.string,
		disabled: PropTypes.bool,
		onChange: PropTypes.func,

		// from connect
		carCategories: PropTypes.array,
		loading: PropTypes.bool,
		loaded: PropTypes.bool,

		// from connect actions
		fetchAll: PropTypes.func
	};

	componentDidMount(){
		if(!this.props.loaded) this.props.fetchAll();
	}

	render(){
		const {className, selectedId, disabled, carCategories, loaded, loading, onChange} = this.props;

		if(loading && !loaded) return <div style={{paddingBottom: '15px'}}>Загрузка категорий авто...</div>;
		if(!loaded) return null;

		const renderedItems = !!carCategories.slice ? carCategories.map((item) => (
			<div className={"o-car-category" + (parseInt(item.ID) === parseInt(selectedId) ? ' o-car-category--selected':'') + (disabled ? ' o-car-category--disabled':'')} key={item.ID} onClick={this.handleClick(item.ID)}>
				<div className="o-car-category__icon"><img src={item.PICTURE} alt=""/></div>
				<div className="o-car-category__title">{item.NAME.substr(0, 10) + (item.NAME.length > 10 ? '...':'')}</div>
			</div>
		)):null;

		return (
			<div className={"o-car-category-group" + (!!className ? ` ${className}`:'')}>
				{renderedItems}
			</div>
		);
	}

	handleClick = id => ev => {
		if(!!this.props.disabled) return;
		if(typeof this.props.onChange === "function") this.props.onChange(id);
	}
}

export default connect(state => ({
	carCategories: carCategoriesSelector(state),
	loading: state[carCategoriesModule].loading,
	loaded: state[carCategoriesModule].loaded,
}), {fetchAll})(CarCategoryCardsSelect);