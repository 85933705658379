import {appName} from '../config';
import {Record} from 'immutable';
import {all, call, put, select, takeEvery} from '../../node_modules/@redux-saga/core/effects';
import BackendApi, {ERROR_NOT_FOUND} from "../BackendApi";
import {backendArrayToOrderedMap, createMoment} from "../helpers";
import {SET_BACKEND_ERROR} from "./backendError";
import {
	backendOrderToRecord,
	ErrorRecord,
	OrderCarwashRecord,
	OrderCouponRecord,
	OrderItemRecord,
	OrderRecord,
	SuccessRecord
} from './_common_records';

export const moduleName = 'order';
const prefix = `${appName}/${moduleName}`;

export const LOAD_REQUEST = `${prefix}/LOAD_REQUEST`;
export const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`;
export const LOAD_ERROR = `${prefix}/LOAD_ERROR`;
export const SET_MANAGER_COMMENT = `${prefix}/SAVE_COMMENT`;
export const SAVE_MANAGER_COMMENT_REQUEST = `${prefix}/SAVE_MANAGER_COMMENT_REQUEST`;
export const SAVE_CONFIRMED_REQUEST = `${prefix}/SAVE_CONFIRMED_REQUEST`;
export const SAVE_CONFIRMED_SUCCESS = `${prefix}/SAVE_CONFIRMED_SUCCESS`;
export const SAVE_CANCELED_REQUEST = `${prefix}/SAVE_CANCELED_REQUEST`;
export const SAVE_CANCELED_SUCCESS = `${prefix}/SAVE_CANCELED_SUCCESS`;
export const CHANGE_DATE_START_REQUEST = `${prefix}/CHANGE_DATE_START_REQUEST`;
export const CHANGE_DATE_START_SUCCESS = `${prefix}/CHANGE_DATE_START_SUCCESS`;
export const SET_SUCCESS = `${prefix}/SET_SUCCESS`;
export const CLEAR_SUCCESS = `${prefix}/CLEAR_SUCCESS`;
export const CLEAR = `${prefix}/CLEAR`;


const ReducerRecord = Record({
	loading: false,
	loaded: false,
	order: null,
	changeDateStartLoading: false,
	error: null,
	success: null,
	canceled: false,
});

export default (state = new ReducerRecord(), action) => {
	const {type, payload} = action;

	switch(type)
	{
		case LOAD_REQUEST:
			return state
				.set('loading', true)
				.set('error', null);

		case LOAD_SUCCESS:
			return state
				.set('loading', false)
				.set('loaded', true)
				.set('order', backendOrderToRecord(payload));

		case LOAD_ERROR:
			return state
				.set('loading', false)
				.set('error', new ErrorRecord(payload));

		case SET_MANAGER_COMMENT:
			return state
				.setIn(['order', 'MANAGER_COMMENT'], payload);

		case SAVE_CONFIRMED_SUCCESS:
			return state
				.setIn(['order', 'CONFIRMED'], true);

		case SAVE_CANCELED_SUCCESS:
			return state
				.set('canceled', true);

		case SET_SUCCESS:
			return state
				.set('success', new SuccessRecord(payload));

		case CLEAR_SUCCESS:
			return state
				.set('success', null);

		case CHANGE_DATE_START_REQUEST:
			return state
				.set('changeDateStartLoading', true);

		case CHANGE_DATE_START_SUCCESS:
			return state
				.set('changeDateStartLoading', false);

		case CLEAR:
			return new ReducerRecord();

		default:
			return state;
	}
}

/* ------ Selectors ------- */

export const stateSelector = state => state[moduleName];

/* ------ Action Creators ------- */

export function load(id){
	return {
		type: LOAD_REQUEST,
		payload: id,
	}
}

export function setManagerComment(value){
	return {
		type: SET_MANAGER_COMMENT,
		payload: value,
	}
}

export function saveManagerComment(){
	return {
		type: SAVE_MANAGER_COMMENT_REQUEST
	}
}

export function saveConfirmed(){
	return {
		type: SAVE_CONFIRMED_REQUEST
	}
}

export function clear(){
	return {
		type: CLEAR
	}
}

/**
 * @param {string} dateStart
 * @param {string|number} boxNumber
 * @return {object}
 */
export function changeDateStart(dateStart, boxNumber){
	return {
		type: CHANGE_DATE_START_REQUEST,
		payload: {dateStart, boxNumber}
	}
}

export function saveCanceled(){
	return {
		type: SAVE_CANCELED_REQUEST
	}
}

export function clearSuccess(){
	return {
		type: CLEAR_SUCCESS
	}
}

/* ------ Sagas -----------------*/

const loadSaga = function* ({payload}){
	const response = yield call([BackendApi, BackendApi.orderDetail], payload);

	if(!!response.result)
	{
		yield put({
			type: LOAD_SUCCESS,
			payload: response.result,
		})
	}
	else
	{
		if(response.error !== ERROR_NOT_FOUND)
		{
			yield put({
				type: SET_BACKEND_ERROR,
				payload: response,
			});
		}
		yield put({
			type: LOAD_ERROR,
			payload: response,
		});
	}
};

const saveManagerCommentSaga = function* (){
	const {order} = yield select(stateSelector);
	if(order.ID)
	{
		const response = yield call([BackendApi, BackendApi.orderAddComment], order.ID, order.MANAGER_COMMENT);
		if(!!response.error)
		{
			yield put({
				type: SET_BACKEND_ERROR,
				payload: response
			});
		}
	}
};

const saveConfirmedSaga = function* (){
	const {order} = yield select(stateSelector);
	if(order.ID)
	{
		const response = yield call([BackendApi, BackendApi.orderConfirm], order.ID);
		if(response.result === true)
		{
			yield put({
				type: SAVE_CONFIRMED_SUCCESS
			});

			const dateStart = createMoment(order.DATE_START);
			const dateEnd = createMoment(order.DATE_END);

			yield put({
				type: SET_SUCCESS,
				payload: {
					title: 'Подтверждено',
					subtitle: `${dateStart.format('LL')}, ${dateStart.format('HH:mm')} - ${dateEnd.format('HH:mm')}`
				}
			});
		}
		else if(!!response.error)
		{
			yield put({
				type: SET_BACKEND_ERROR,
				payload: response
			});
		}
	}
};

const saveCanceledSaga = function* (){
	const {order} = yield select(stateSelector);
	if(order.ID)
	{
		const response = yield call([BackendApi, BackendApi.orderCancel], order.ID);
		if(response.result === true)
		{
			yield put({
				type: SAVE_CANCELED_SUCCESS
			});

			const dateStart = createMoment(order.DATE_START);
			const dateEnd = createMoment(order.DATE_END);

			yield put({
				type: SET_SUCCESS,
				payload: {
					title: 'Отменено',
					subtitle: `${dateStart.format('LL')}, ${dateStart.format('HH:mm')} - ${dateEnd.format('HH:mm')}`
				}
			});
		}
		else if(!!response.error)
		{
			yield put({
				type: SET_BACKEND_ERROR,
				payload: response
			});
		}
	}
};

const changeDateStartSaga = function* ({payload}){
	const {order} = yield select(stateSelector);
	const {dateStart, boxNumber} = payload;

	const response = yield call([BackendApi, BackendApi.orderChangeDateStart], order.ID, dateStart, boxNumber);
	if(!!response.result)
	{
		yield put({
			type: CHANGE_DATE_START_SUCCESS
		});

		yield put({
			type: LOAD_REQUEST,
			payload: order.ID
		});
	}
	else if(!!response.error)
	{
		yield put({
			type: SET_BACKEND_ERROR,
			payload: response
		});
	}
};

export const saga = function* (){
	yield all([
		takeEvery(LOAD_REQUEST, loadSaga),
		takeEvery(SAVE_MANAGER_COMMENT_REQUEST, saveManagerCommentSaga),
		takeEvery(SAVE_CONFIRMED_REQUEST, saveConfirmedSaga),
		takeEvery(SAVE_CANCELED_REQUEST, saveCanceledSaga),
		takeEvery(CHANGE_DATE_START_REQUEST, changeDateStartSaga),
	])
};