export const appName = "jantar";
//export const backedUrl = "https://jantar.pro/rest/";
export const backedUrl = process.env.NODE_ENV === 'development' ? "http://jantar.local/rest/":"http://jantar.mheads.ru/rest/";
export const fromNames = {
	app: 'Приложение',
	site: 'Сайт',
	phone: 'Телефон',
};
export const paidNames = {
	Y: 'Оплачено',
	N: 'Не оплачено',
};

Date.prototype.daysInMonth = function() {
	return 33 - new Date(this.getFullYear(), this.getMonth(), 33).getDate();
};
