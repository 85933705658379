import cookie from 'react-cookies';
const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const CURRENT_PROFILE = 'current_pofile';

class CookieStor {
	static setAccessToken(value){
		this.set(ACCESS_TOKEN, value);
	}
	static getAccessToken(){
		return this.get(ACCESS_TOKEN);
	}

	static setRefreshToken(value){
		this.set(REFRESH_TOKEN, value);
	}
	static getRefreshToken(){
		return this.get(REFRESH_TOKEN);
	}

	static setCurrentProfile(value){
		this.set(CURRENT_PROFILE, JSON.stringify(value));
	}

	static getCurrentProfile(){
		try
		{
			return JSON.parse(this.get(CURRENT_PROFILE));
		}
		catch(e)
		{
			return null;
		}
	}

	static set(name, value) {
		const expires = new Date();
		expires.setDate(expires.getDate() + 14);

		cookie.save(name, value, { path: '/', expires: expires})
	}

	static get(name) {
		return cookie.load(name);
	}
}

export default CookieStor;