import BackendApi from '../BackendApi';
import {appName} from '../config';
import {Record} from 'immutable';
import {all, call, put, select, take, takeEvery} from '../../node_modules/@redux-saga/core/effects';
import {eventChannel} from 'redux-saga';
import {createSelector} from 'reselect';
import LocalStor from "../localStor";

export const moduleName = 'auth';

export const SIGN_IN_REQUEST = `${appName}/${moduleName}/SIGN_IN_REQUEST`;
export const SIGN_IN_ERROR = `${appName}/${moduleName}/SIGN_IN_ERROR`;
export const SIGN_IN_SUCCESS = `${appName}/${moduleName}/SIGN_IN_SUCCESS`;

export const LOAD_PROFILE_REQUEST = `${appName}/${moduleName}/LOAD_PROFILE_REQUEST`;
export const LOAD_PROFILE_SUCCESS = `${appName}/${moduleName}/LOAD_PROFILE_SUCCESS`;
export const LOAD_PROFILE_ERROR = `${appName}/${moduleName}/LOAD_PROFILE_ERROR`;

export const SIGN_OUT_REQUEST = `${appName}/${moduleName}/SIGN_OUT_REQUEST`;
export const SIGN_OUT_SUCCESS = `${appName}/${moduleName}/SIGN_OUT_SUCCESS`;

export const HELLO_CONFIRM_REQUEST = `${appName}/${moduleName}/HELLO_CONFIRM_REQUEST`;
export const HELLO_CONFIRM_SUCCESS = `${appName}/${moduleName}/HELLO_CONFIRM_SUCCESS`;

const ReducerRecord = Record({
	signedIn: !!BackendApi.getAccessToken(),
	signInLoading: false,
	signInError: null,

	profile: null,
	profileLoaded: false,
	profileLoading: false,
	profileError: null,

	helloConfirmed: false,
});

export default function reducer(state = new ReducerRecord(), action){
	const {type, payload, error} = action;
	switch(type)
	{
		case SIGN_IN_REQUEST:
			return (new ReducerRecord())
				.set('signedIn', false)
				.set('signInLoading', true);

		case SIGN_IN_SUCCESS:
			return state
				.set('signInLoading', false)
				.set('signedIn', true)
				.set('signInError', null);

		case SIGN_IN_ERROR:
			return state
				.set('signInLoading', false)
				.set('signInError', error);

		case LOAD_PROFILE_REQUEST:
			return state
				.set('profileLoading', true)
				.set('profileError', null);

		case LOAD_PROFILE_SUCCESS:
			return state
				.set('profileLoading', false)
				.set('profileLoaded', true)
				.set('profile', payload.profile)
				.set('profileError', null);

		case LOAD_PROFILE_ERROR:
			return state
				.set('profileLoading', false)
				.set('profileLoaded', false)
				.set('profile', null)
				.set('profileError', error);

		case SIGN_OUT_SUCCESS:
			return (new ReducerRecord())
				.set('signedIn', false);

		case HELLO_CONFIRM_SUCCESS:
			return state.set('helloConfirmed', true);

		default:
			return state
	}
}

/* ------ Selectors ------- */

export const stateSelector = state => state[moduleName];
export const profileSelector = createSelector(stateSelector, state => state.profile);

/* ------ Action Creators ------- */

export function signIn(login, password){
	return {
		type: SIGN_IN_REQUEST,
		payload: {login, password}
	}
}

export function loadProfile(){
	return {
		type: LOAD_PROFILE_REQUEST
	}
}

export function signOut(){
	return {
		type: SIGN_OUT_REQUEST
	}
}

export function helloConfirm(){
	return {
		type: HELLO_CONFIRM_REQUEST
	}
}

/* ------ Sagas -----------------*/

const signInSaga = function* (action){
	const signedIn = yield call([BackendApi, BackendApi.login], action.payload.login, action.payload.password);
	if(signedIn)
	{
		yield put({
			type: SIGN_IN_SUCCESS
		});
	}
	else
	{
		yield put({
			type: SIGN_IN_ERROR,
			error: 'not signed'
		})
	}
};

const signInSuccessSaga = function* (){
	yield put({
		type: LOAD_PROFILE_REQUEST
	})
};

const signOutSaga = function* (){
	yield call([BackendApi, BackendApi.logout]);
	yield put({
		type: SIGN_OUT_SUCCESS
	});
};

const watchSignInChange = function* (){
	const chan = yield call(() => eventChannel(emit => {
		const interval = setInterval(function(){
			const accessToken = BackendApi.getAccessToken();
			emit(!!accessToken);
		}, 0);
		return () => {
		};
	}));

	while(true)
	{
		const isSignedInApi = yield take(chan);
		const state = yield select(stateSelector);

		if(!isSignedInApi && state.signedIn)
		{
			yield put({
				type: SIGN_OUT_REQUEST
			});
		}
	}
};

const loadProfileSaga = function* (){
	const response = yield call([BackendApi, BackendApi.profile]);
	if(response.result)
	{
		yield put({
			type: LOAD_PROFILE_SUCCESS,
			payload: {
				profile: response.result
			}
		})
	}
	else
	{
		yield put({
			type: LOAD_PROFILE_ERROR,
			error: response.error
		})
	}
};

const helloConfirmSaga = function* (){
	try
	{
		yield call(function(){
			LocalStor.setHelloConfirmed(1);
		});
		yield put({
			type: HELLO_CONFIRM_SUCCESS
		});
	} catch(_)
	{
	}
};

export const saga = function* (){
	yield all([
		takeEvery(SIGN_IN_REQUEST, signInSaga),
		takeEvery(SIGN_IN_SUCCESS, signInSuccessSaga),
		takeEvery(LOAD_PROFILE_REQUEST, loadProfileSaga),
		takeEvery(SIGN_OUT_REQUEST, signOutSaga),
		takeEvery(HELLO_CONFIRM_REQUEST, helloConfirmSaga),
		watchSignInChange()
	])
};