import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
	load,
	moduleName as orderModule,
	setManagerComment,
	saveManagerComment,
	clearSuccess,
	saveConfirmed,
	saveCanceled,
	changeDateStart,
	clear
} from '../../ducks/order';
import {profileSelector, moduleName as authModule} from '../../ducks/auth';
import {push} from '../../history';
import {calculateTimeStatus, createMoment, toString} from "../../helpers";
import {ERROR_NOT_FOUND} from "../../BackendApi";
import StatusLables from "./common/StatusLables";
import IconTimeStatus from "./common/IconTimeStatus";
import DetailProp from "./common/DetailProp";
import Popup from "../common/Popup";
import DetailPropList from "./common/DetailPropList";
import ServiceDetailProp from "./common/ServiceDetailProp";
import AsidePopupDateTimeSelect from "./common/AsidePopupDateTimeSelect";

class DetailOrder extends Component {
	static propTypes = {
		id: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string
		]).isRequired,

		// from connect
		loading: PropTypes.bool,
		loaded: PropTypes.bool,
		changeDateStartLoading: PropTypes.bool,
		order: PropTypes.object,
		error: PropTypes.object,
		success: PropTypes.object,
		canceled: PropTypes.bool,
		profile: PropTypes.object,

		// from connect actions
		load: PropTypes.func,
		setManagerComment: PropTypes.func,
		saveManagerComment: PropTypes.func,
		clearSuccess: PropTypes.func,
		saveConfirmed: PropTypes.func,
		saveCanceled: PropTypes.func,
		changeDateStart: PropTypes.func,
		clear: PropTypes.func,
	};

	constructor(props){
		super(props);

		this.state = {
			isOpenDateTimeSelect: false,
			showConfirmCancel: false,
		}
	}

	componentDidMount(){
		this.props.load(this.props.id);
	}

	render(){
		const {error, order, canceled, profile} = this.props;
		if(error && error.error === ERROR_NOT_FOUND) return this.get404();

		const isAdmin = !!profile && !!profile.IS_ADMIN;

		return (
			<div>
				{!canceled ? (
					<div>
						<div className="aside-popup-wrap" onClick={this.handleClose}/>
						<div className="aside-popup-group">
							<div className="aside-popup">
								<div className="aside-popup__head">
									{this.getRenderedHead()}
									<div className="aside-popup__head__close" onClick={this.handleClose}>закрыть</div>
								</div>
								<div className="aside-popup__body">
									{this.getRenderedBody()}
								</div>
								<div className="aside-popup__foot">
									{this.getRenderedFooter()}
									{!!order && order.FROM === 'app' && isAdmin && (
										<button
											className="btn btn--white-backing w100"
											onClick={() => this.setState({showConfirmCancel: true})}
										><span>Отменить</span></button>
									)}
								</div>
							</div>
							{order && (
								<AsidePopupDateTimeSelect
									isOpen={this.state.isOpenDateTimeSelect}
									handleClose={this.handleDateTimeSelectShow(false)}
									onSelect={this.handleDateStartSelect}
									order={order}
								/>
							)}
						</div>
					</div>
				):null}
				{this.getSuccessPopup()}
				{!!this.state.showConfirmCancel && (
					<Popup
						type="error"
						title="Отменить заказ"
						subtitle="Вы действительно хотите отменить заказ из мобильного приложения?"
					>
						{!!order.PAID && (
							<p style={{textAlign: 'center', marginTop: 15, color: 'red'}}>
								<b>Заказ оплачен!</b><br/>
								Возврат оплаты необходимо осуществлять в ЛК эквайринга банка.
							</p>
						)}
						<div className="signin-form__button-wrap">
							<button className="signin-form__button signin-form__button-cancel" type="button" onClick={() => this.setState({showConfirmCancel: false})} style={{marginBottom: 10}}>Не отменять</button>
							<button className="signin-form__button" type="button" onClick={() => {
								this.props.saveCanceled();
								this.setState({showConfirmCancel: false});
							}}>Отменить заказ</button>
						</div>
					</Popup>
				)}
			</div>
		)
	}

	getRenderedHead(){
		const {id} = this.props;

		return (
			<h2>
				№ {id} <IconTimeStatus timeStatus={this.calculateTimeStatus()}/>
			</h2>
		)
	}

	getRenderedBody(){
		const {order, error, loading, loaded, changeDateStartLoading} = this.props;
		if(loading && !loaded) return 'загрузка...';
		if(error) return <div style={{color: 'red'}}>{error.error_description}</div>;
		if(!order) return null;

		const dateReserveStart = createMoment(order.DATE_START);
		const dateReserveEnd = createMoment(order.DATE_END);

		const managerComment = toString(order.MANAGER_COMMENT);
		const clientComment = toString(order.CLIENT_COMMENT);

		const canDateStartChange = this.canDateStartChange();
		const dateStartText = changeDateStartLoading ? 'Обновляется...':`${dateReserveStart.format('LL')}, ${dateReserveStart.format('HH:mm')} - ${dateReserveEnd.format('HH:mm')}, ${order.BOX_NUMBER} бокс`;
		return (
			<div>
				<DetailPropList className="mb20">
					<DetailProp iconLeft="user">
						<span className="bold">{order.CLIENT_NAME}</span><br/>
						<span className="c-gray">{order.CLIENT_PHONE}</span>
					</DetailProp>
					<DetailProp iconLeft="car">
						<span className="bold">{order.CAR_NUMBER}</span><br/>
						<span className="c-gray">{order.CAR_MARK} {order.CAR_MODEL}</span>
					</DetailProp>
					<ServiceDetailProp orderItems={order.ITEMS.valueSeq().toArray()}/>
					<DetailProp iconLeft="pin">
						{order.CARWASH ? order.CARWASH.ADDRESS:''}
					</DetailProp>
					<DetailProp
						iconLeft="calendar"
						hasError={parseInt(order.MORE_TIME_NEED) > 0}
						errorText={parseInt(order.MORE_TIME_NEED) > 0 ? `Не хватает ${order.MORE_TIME_NEED_DISPLAY}`:''}
						iconRight={canDateStartChange ? "arrRight":null}
						hovered={canDateStartChange}
						selected={this.state.isOpenDateTimeSelect}
						onClick={canDateStartChange ? this.handleDateTimeSelectShow(!this.state.isOpenDateTimeSelect):null}
					>
						{dateStartText}
					</DetailProp>
					{!!clientComment.length && (
						<DetailProp>
							<b>Комментарий клиента</b>
							<div>{clientComment}</div>
						</DetailProp>
					)}
					<textarea value={managerComment} onChange={this.handleManagerCommentChange} onBlur={this.handleManagerCommentChangeEnd} className={'o-comment-textarea' + (!managerComment.length ? ' o-comment-textarea--empty':'')} placeholder="+ Добавить комментарий"/>
				</DetailPropList>

				<div className="o-summary mb20">
					<div className="o-summary__item">
						<span className="c-dark-black fs19 bold">Итого</span>
						{order.PRICE > order.PAY_PRICE && (
							<span className="crossed c-gray fs19">{parseFloat(order.PRICE).toLocaleString()} ₽</span>
						)}
						<span className="c-dark-black fs19 bold">{parseFloat(order.PAY_PRICE).toLocaleString()} ₽</span>
					</div>
					{order.USED_BONUS_BALANCE > 0 && (
						<div className="o-summary__item">
							<span>Оплачено бонусами: </span>
							<span>{parseFloat(order.USED_BONUS_BALANCE).toLocaleString()} ₽</span>
						</div>
					)}
					{order.COUPON.ID && (
						<div className="o-summary__item">
							<span>Промокод: </span>
							<span>{order.COUPON.CODE}, {order.COUPON.NAME}</span>
						</div>
					)}
					{order.BONUS_REWARD > 0 && (
						<div className="o-summary__item">
							<span>Бонусы начислены</span>
							<span>+ {parseFloat(order.BONUS_REWARD).toLocaleString()} ₽</span>
						</div>
					)}
				</div>

				<div className="o-status-group">
					<StatusLables from={order.FROM} confirmed={order.CONFIRMED} paid={order.PAID}/>
				</div>
			</div>
		)
	}

	getRenderedFooter(){
		const {order} = this.props;
		if(!order) return null;

		const timeStatus = this.calculateTimeStatus();

		switch(this.calculateTimeStatus())
		{
			case 'wait':
				if(order.FROM !== 'site' && order.FROM !== 'phone') return null;
				return (
					<div>
						{!order.CONFIRMED && (
							<button className="btn btn--yellow w100 mb10" onClick={() => this.props.saveConfirmed()}><span>Подтвердить</span></button>
						)}
						<button className="btn btn--white-backing w100" onClick={() => this.props.saveCanceled()}><span>Отменить</span></button>
					</div>
				);

			case 'executing':
			case 'success':
				return (
					<div className="bold acenter">
						{timeStatus === 'executing' ? 'Идёт мойка':'Завершено'}
					</div>
				);
		}

		return null;
	}

	getSuccessPopup(){
		const {success} = this.props;
		if(!success) return null;
		return (
			<Popup type="success" title={success.title} subtitle={success.subtitle}>
				{toString(success.message).length > 0 && (
					<p>{success.message}</p>
				)}
				<div className="signin-form__button-wrap">
					<button className="signin-form__button" type="button" onClick={this.handleCloseSuccessClick}>ОК</button>
				</div>
			</Popup>
		)
	}

	get404(){
		return (
			<div>
				<div className="aside-popup-wrap" onClick={this.handleClose}/>
				<div className="aside-popup">
					<div className="aside-popup__head">
						<h2>Ошибка!</h2>
						<div className="aside-popup__head__close" onClick={this.handleClose}>закрыть</div>
					</div>
					<div className="aside-popup__body">
						<div className="h2">Заказ №{this.props.id} не найден.</div>
					</div>
				</div>
			</div>
		)
	}

	calculateTimeStatus(){
		const {order} = this.props;
		if(!order) return null;

		const dateReserveStart = createMoment(order.DATE_START);
		const dateReserveEnd = createMoment(order.DATE_END);

		return dateReserveStart.isValid() && dateReserveEnd.isValid() ? calculateTimeStatus(dateReserveStart, dateReserveEnd):null;
	}

	canDateStartChange() {
		const {order} = this.props;
		return order && order.FROM !== 'app';
	}

	handleCloseSuccessClick = ev => {
		this.props.clearSuccess();
		if(this.props.canceled){
			this.handleClose();
		}
	};

	handleManagerCommentChange = ev => {
		this.props.setManagerComment(ev.target.value);
	};

	handleManagerCommentChangeEnd = () => {
		this.props.saveManagerComment();
	};

	handleClose = () => {
		this.props.clear();
		push('/');
	};

	handleDateTimeSelectShow = show => () => {
		this.setState({
			isOpenDateTimeSelect: !!show
		});
	};

	/**
	 * @property {moment.Moment} dateStart
	 * @property {moment.Moment} dateEnd
	 * @property {number, string} boxNumber
	 */
	handleDateStartSelect = (dateStart, dateEnd, boxNumber) => {
		this.props.changeDateStart(dateStart.toISOString(), boxNumber);
	};
}

export default connect(state => {
	return {
		loading: state[orderModule].loading,
		loaded: state[orderModule].loaded,
		order: state[orderModule].order,
		error: state[orderModule].error,
		success: state[orderModule].success,
		canceled: state[orderModule].canceled,
		changeDateStartLoading: state[orderModule].changeDateStartLoading,
		profile: state[authModule].profile,
	}
}, {
	load,
	setManagerComment,
	saveManagerComment,
	clearSuccess,
	saveConfirmed,
	saveCanceled,
	changeDateStart,
	clear
})(DetailOrder);
