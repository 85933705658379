import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

export const ICON_PIN = 'pin';
export const ICON_CALENDAR = 'calendar';

class Select extends Component {
	static propTypes = {
		icon: PropTypes.string,

		// react-select props
		name: PropTypes.string,
		defaultValue: PropTypes.object,
		defaultOptions: PropTypes.array,
		options: PropTypes.array,
		onChange: PropTypes.func,
	};

	render(){
		const {icon, ...other} = this.props;

		const removeControlStyles = {
			control: (provided, state) => {
				return {}
			}
		};

		return (
			<ReactSelect
				className={'react-select-container' + (icon ? ' react-select-container--icon-' + icon:'')}
				classNamePrefix='react-select'
				styles={removeControlStyles}
				isSearchable={false}
				{...other}
			/>
		)
	}
}

export default Select;