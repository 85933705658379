import {backedUrl} from './config';
import CookieStor from './cookieStor';
import LocalStor from './localStor';
import {stringify} from 'qs';

const MAX_REFRESH_TOKEN_REQUEST = 1;
let refreshTokenRequestCount = 0;

export const ERROR_NOT_FOUND = 'NOT_FOUND';

class BackendApi {
	/**
	 * @param {string} url
	 * @param {{method?: string, headers?: object, body?: string|object|FormData}} [options]
	 * @return {Promise}
	 */
	static sendRequest(url, options){
		const self = this;
		options = options || {};
		let method = options.method || 'GET';
		let headers = options.headers || {'Accept': 'application/json'};
		let body = options.body || null;
		let requestUrl = url;

		if(method === 'POST' || body)
		{
			headers['Content-Type'] = (body instanceof FormData) ? 'multipart/form-data':'application/x-www-form-urlencoded';
			method = 'POST';
		}

		if(body && typeof body === 'object' && !(body instanceof FormData))
		{
			body = stringify(body);
		}

		return new Promise((resolve, reject) => {
			fetch(requestUrl, {
				method,
				headers,
				body,
			})
				.then((response) => {
					response.json().then((data) => {
						data['responseStatus'] = response.status;
						data['responseStatusText'] = response.statusText;

						resolve(data);
					})
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	/**
	 * @param {string} url
	 * @param {{method?: string, headers?: object, body?: string|object|FormData}} [options]
	 * @return {Promise}
	 */
	static async sendRequestWithAuth(url, options){
		let urlWithAuth = url + (url.indexOf('?') !== -1 ? '&':'?') + 'auth=' + this.getAccessToken();

		const response = await this.sendRequest(urlWithAuth, options);

		if(response.responseStatus === 401 && response.error)
		{
			switch(response.error)
			{
				case 'expired_token':
					if(this.getRefreshToken())
					{
						while(refreshTokenRequestCount < MAX_REFRESH_TOKEN_REQUEST)
						{
							++refreshTokenRequestCount;
							if(await this.refreshToken(refreshTokenRequestCount === MAX_REFRESH_TOKEN_REQUEST))
							{
								refreshTokenRequestCount = 0;
								return await this.sendRequestWithAuth(url, options);
							}
						}
					}
					break;

				case 'invalid_token':
				case 'NO_AUTH_FOUND':
					this.logout();
					break;
			}
		}

		return response;
	}

	/**
	 * @param {boolean} [logout]
	 * @return boolean
	 */
	static async refreshToken(logout){
		try
		{
			const {result} = await this.sendRequest(backedUrl + "mhmerpcarwash.auth.token.refresh", {
				body: {
					refresh_token: this.getRefreshToken()
				}
			});

			if(result && result['access_token'])
			{
				this.setAccessToken(result['access_token']);
				this.setRefreshToken(result['refresh_token']);
				return true;
			}
		}
		catch(e)
		{
		}

		if(logout) this.logout();

		return false;
	}

	static async login(login, password){
		try
		{
			this.logout();

			const {result} = await this.sendRequest(backedUrl + "mhmerpcarwash.auth.token.generate", {
				body: {
					login: login,
					password: password
				}
			});

			if(result && result['access_token'])
			{
				this.setAccessToken(result['access_token']);
				this.setRefreshToken(result['refresh_token']);
				return true;
			}
		}
		catch(e)
		{
		}

		return false;
	}

	static async profile(){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.manager.profile");
	}

	static async orderListWithCarwashBoxNumbers(filter){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.manager.order.list_with_carwash_box_numbers", {
			body: {
				filter: filter
			}
		});
	}

	static async orderDetail(id){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.manager.order.detail?id=" + id);
	}

	static async orderAddComment(id, comment){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.manager.order.add_comment", {
			body: {id, comment}
		});
	}

	static async orderConfirm(id){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.manager.order.confirm?id=" + id);
	}

	static async orderCancel(id){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.manager.order.cancel?id=" + id);
	}

	static async orderChangeDateStart(id, dateStart, boxNumber){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.manager.order.change_date_start", {
			body: {
				id: id,
				date_start: dateStart,
				box_number: boxNumber,
			}
		});
	}

	/**
	 * @param {{carwash_id: number, box_number: number, date_start: string, service_id: number[], comment: string, car_number: string, car_category_id: number, client_phone: string, client_name: string}} order
	 * @param {bool} [save]
	 * @return {object}
	 */
	static async orderAdd(order, save){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.manager.order.add", {
			body: {...order, save: save ? 1:0}
		});
	}

	/**
	 * @param {{date: string, carwash_id: number|string, service_id: number[], order_id: number|string, duraton: number|string}} data
	 * @return {object}
	 */
	static async orderFindFreeReserving(data){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.manager.order.find_free_reserving", {
			body: data
		});
	}

	static async carCategories(){
		return await this.sendRequest(backedUrl + "mhmerpcarwash.car.categories");
	}

	static async servicesAndCategories(filter){
		return await this.sendRequest(backedUrl + "mhmerpcarwash.service.services_and_categories", {
			body: {
				filter: filter
			}
		});
	}

	static async carwshBoxUp(id, date, timeWorkFrom, timeWorkTo){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.carwash.box.up", {
			body: {id, date, timeWorkFrom, timeWorkTo}
		})
	}

	static async carwshBoxDown(id, date){
		return await this.sendRequestWithAuth(backedUrl + "mhmerpcarwash.carwash.box.down", {
			body: {id, date}
		})
	}

	static logout(){
		CookieStor.setAccessToken('');
		CookieStor.setRefreshToken('');
		LocalStor.setHelloConfirmed('');
		return true;
	}

	static setAccessToken(token){
		CookieStor.setAccessToken(token);
	}

	static setRefreshToken(token){
		CookieStor.setRefreshToken(token);
	}

	static getAccessToken(){
		return CookieStor.getAccessToken();
	}

	static getRefreshToken(){
		return CookieStor.getRefreshToken();
	}
}

export default BackendApi;
