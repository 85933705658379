import React, {Component} from 'react';
import {connect} from 'react-redux'
import {moduleName} from "../../ducks/auth";
import LocalStor from '../../localStor';
import PropTypes from "prop-types";

class SignSuccess extends Component {
	static propTypes = {
		auth: PropTypes.object,
		buttonClickHandle: PropTypes.func
	};

	componentDidMount(){
		if(!!LocalStor.getHelloConfirmed())
		{
			this.props.buttonClickHandle();
		}
	}

	render(){
		const {auth, buttonClickHandle} = this.props;
		if(!auth.signedIn) return '';
		if(auth.profileLoading) return (
			<div className="popup-wrap">
				<div className="popup popup--success">
					<div className="acenter">
						<span className="popup__ok"> </span>
					</div>
					<h2 className="popup__title">Вход выполнен.</h2>
					<div className="popup__enter-in">Загрузка профиля...</div>
				</div>
			</div>
		);
		if(!auth.profile) return '';

		const {NAME, LAST_NAME} = auth.profile;
		const date = new Date();

		return (
			<div className="popup-wrap">
				<div className="popup popup--success">
					<div className="acenter">
						<span className="popup__ok"> </span>
					</div>
					<h2 className="popup__title">Привет, {NAME} {LAST_NAME}<br/>хорошего дня</h2>
					<div className="popup__enter-in">Вход в {date.getHours()+''.length === 1 ? '0':''}{date.getHours()}:{date.getMinutes()+''.length === 1 ? '0':''}{date.getMinutes()}</div>
					<div className="signin-form__button-wrap">
						<button className="signin-form__button" type="button" onClick={buttonClickHandle}>ОК</button>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(state => ({
	auth: state[moduleName]
}))(SignSuccess)