import {appName} from '../config';
import {List, Record, OrderedMap} from 'immutable';
import {all, call, put, select, takeEvery} from '../../node_modules/@redux-saga/core/effects';
import {backendOrderToRecord, OrderRecord, SuccessRecord} from './_common_records';
import BackendApi from "../BackendApi";
import {SET_BACKEND_ERROR} from "./backendError";
import {createSelector} from "reselect";

export const moduleName = 'createOrder';
const prefix = `${appName}/${moduleName}`;

export const SET_FIELD = `${prefix}/SET_FIELD`;
export const PREVIEW_REQUEST = `${prefix}/PREVIEW_REQUEST`;
export const PREVIEW_SUCCESS = `${prefix}/PREVIEW_SUCCESS`;
export const SAVE_REQUEST = `${prefix}/SAVE_REQUEST`;
export const SAVE_SUCCESS = `${prefix}/SAVE_SUCCESS`;
export const SAVE_ERROR = `${prefix}/SAVE_ERROR`;
export const CLEAR = `${prefix}/CLEAR`;


const ReducerRecord = Record({
	order: new OrderRecord,
	saveLoading: false,
	saved: false,
});

const allowedSetFields = {
	CARWASH_ID: true,
	BOX_NUMBER: true,
	DATE_START: true,
	CLIENT_NAME: true,
	CLIENT_PHONE: true,
	CAR_CATEGORY_ID: true,
	CAR_NUMBER: true,
	MANAGER_COMMENT: true,
	DURATION: true,
	CUSTOM_DURATION: true,
	serviceIds: true,
};

export default (state = new ReducerRecord(), action) => {
	const {type, payload} = action;

	switch(type)
	{
		case SET_FIELD:
			const {field, value} = payload;
			if(!allowedSetFields[field]) return state;
			switch(field)
			{
				case 'serviceIds':
					return state.setIn(['order', field], List(value));

				case 'DATE_START':
					return state
						.setIn(['order', field], value.toISOString())
						.setIn(['order', 'DATE_END'], null);
			}
			return state.setIn(['order', field], value);

		case PREVIEW_SUCCESS:
			return state
				.set('order', backendOrderToRecord(payload));

		case SAVE_REQUEST:
			return state.set('saveLoading', true);

		case SAVE_ERROR:
			return state.set('saveLoading', false);

		case SAVE_SUCCESS:
			return state
				.set('saveLoading', false)
				.set('saved', true)
				.set('order', backendOrderToRecord(payload));

		case CLEAR:
			return new ReducerRecord();

		default:
			return state;
	}
}

/* ------ Selectors ------- */

export const stateSelector = state => state[moduleName];
export const orderSelector = createSelector(stateSelector, state => state.order);
export const serviceIdsSelector = createSelector(orderSelector, order => order.serviceIds.toArray());

/* ------ Action Creators ------- */

export function setField(field, value){
	return {
		type: SET_FIELD,
		payload: {field, value}
	}
}

let previewRequestCounter = 0;
export function preview(){
	return {
		type: PREVIEW_REQUEST,
		payload: ++previewRequestCounter
	}
}

export function clear(){
	return {
		type: CLEAR
	}
}

export function save(){
	return {
		type: SAVE_REQUEST
	}
}

/* ------ Sagas -----------------*/

const previewSaga = function* (action){
	const numRequest = action.payload;
	const {order, saved} = yield select(stateSelector);
	if(!saved)
	{
		const response = yield call([BackendApi, BackendApi.orderAdd], orderToBackendApiAdd(order));
		if(previewRequestCounter === numRequest)
		{
			if(!!response.result)
			{
				yield put({
					type: PREVIEW_SUCCESS,
					payload: response.result
				})
			}
			else if(!!response.error)
			{
				yield put({
					type: SET_BACKEND_ERROR,
					payload: response
				});
			}
		}
	}
	else {
		yield put({
			type: SAVE_ERROR,
			payload: {error: 'REPEAT_SAVE', error_description: 'Заказ уже добавлен'}
		});
	}
};

const saveSaga = function* (action){
	const {order, saved} = yield select(stateSelector);
	if(!saved)
	{
		const response = yield call([BackendApi, BackendApi.orderAdd], orderToBackendApiAdd(order), true);
		if(!!response.result)
		{
			yield put({
				type: SAVE_SUCCESS,
				payload: response.result
			})
		}
		else if(!!response.error)
		{
			yield put({
				type: SAVE_ERROR,
				payload: response
			});
		}
	}
	else {
		yield put({
			type: SAVE_ERROR,
			payload: {error: 'REPEAT_SAVE', error_description: 'Заказ уже добавлен'}
		});
	}
};

const saveErrorSaga = function* ({payload}){
	yield put({
		type: SET_BACKEND_ERROR,
		payload: payload
	});
};

export const saga = function* (){
	yield all([
		takeEvery(PREVIEW_REQUEST, previewSaga),
		takeEvery(SAVE_REQUEST, saveSaga),
		takeEvery(SAVE_ERROR, saveErrorSaga),
	])
};

/* ---- helpers ----------- */

function orderToBackendApiAdd(order){
	const result = {
		carwash_id: order.CARWASH_ID,
		box_number: order.BOX_NUMBER,
		date_start: order.DATE_START,
		duration: order.DURATION,
		custom_duration: !!order.CUSTOM_DURATION ? 1:0,
		service_id: order.serviceIds.toArray(),
		comment: order.MANAGER_COMMENT,
		car_number: order.CAR_NUMBER,
		car_category_id: order.CAR_CATEGORY_ID,
		client_phone: order.CLIENT_PHONE,
		client_name: order.CLIENT_NAME
	};

	return result;
}