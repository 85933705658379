import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
	moduleName as ordersModule
} from "../../ducks/orders";
import PropTypes from "prop-types";
import {createMoment} from "../../helpers";

class RevenuePlan extends Component {
	static propTypes = {
		profile: PropTypes.object.isRequired,

		// from connect
		carwashId: PropTypes.number
	};

	render(){
		const carwah = this.getCarwash();
		if(!carwah) return null;

		const month = createMoment().format('MMMM');

		return (
			<div className="container">
				<div className="revenue_plan">
					<div className="revenue_plan__stat">
						<div className="revenue_plan__stat__name">
							План на <span>{month.charAt(0).toUpperCase() + month.slice(1)}</span>
						</div>
						<div className="revenue_plan__stat__value">
							{carwah.REVENUE_PLAN.AMOUNT_PLAN ? parseFloat(carwah.REVENUE_PLAN.AMOUNT_PLAN).toLocaleString():'---'} р
						</div>
					</div>
					<div className="revenue_plan__stat revenue_plan__stat--gray">
						<div className="revenue_plan__stat__name">
							До выполнения плана
						</div>
						<div className="revenue_plan__stat__value">
							<b>{carwah.REVENUE_PLAN.AMOUNT_REMAIN ? parseFloat(carwah.REVENUE_PLAN.AMOUNT_REMAIN).toLocaleString():'---'} р</b>
						</div>
					</div>
					<div className="revenue_plan__stat revenue_plan__stat--yellow">
						<div className="revenue_plan__stat__name">
							План на сегодня
						</div>
						<div className="revenue_plan__stat__value">
							{carwah.REVENUE_PLAN.AMOUNT_DAY ? parseFloat(carwah.REVENUE_PLAN.AMOUNT_DAY).toLocaleString():'---'} р
						</div>
					</div>
					<div className="revenue_plan__stat">
						<div className="revenue_plan__stat__name">
							Осталось
						</div>
						<div className="revenue_plan__stat__value">
							{carwah.REVENUE_PLAN.DAYS_LEFT} дн.
						</div>
					</div>
				</div>
			</div>
		)
	}

	getCarwash() {
		const {carwashId, profile} = this.props;
		if(!carwashId || !profile.CARWASHES) return null;

		const carwash = profile.CARWASHES.find(item => item.ID === carwashId);

		return carwash && carwash.REVENUE_PLAN !== null && !!carwash.REVENUE_PLAN.SHOW_ON_PANEL ? carwash:null;
	}
}

export default connect(state => ({
	carwashId: !!state[ordersModule].filter.CARWASH_ID ? state[ordersModule].filter.CARWASH_ID:null,
}))(RevenuePlan)
