import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DetailProp from "./DetailProp";

class ServiceDetailProp extends Component {
	static propTypes = {
		orderItems: PropTypes.array,
		nonMessage: PropTypes.string,
	};

	static defaultProps = {
		orderItems: [],
		nonMessage: 'Не выбрано'
	};

	constructor(props){
		super(props);
		this.state = {
			isOpen: false
		}
	}

	render(){
		const {orderItems, nonMessage, ...rest} = this.props;

		const renderedServices = !!orderItems.slice && orderItems.length ? (
			this.state.isOpen ? orderItems.map((item) => {
				return <div key={item.ID} className="fs12">{item.SERVICE_NAME}</div>
			}):(
				<div className="fs12">
					{orderItems[0] ? orderItems[0].SERVICE_NAME:'---'}
					<div>{(orderItems.length - 1) > 0 ? '+ ' + (orderItems.length - 1) + " доп услуги":''}</div>
				</div>
			)
		):nonMessage;

		return (
			<DetailProp
				iconLeft="cart"
				hovered={!!orderItems.slice && orderItems.length > 1}
				onClick={this.handleOpenToggle}
				{...rest}
			>
				{renderedServices}
			</DetailProp>
		);
	}

	handleOpenToggle = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		})
	}
}

export default ServiceDetailProp;