import React from 'react';
import PropTypes from 'prop-types';
import {toString} from '../../../helpers';
import userSvg from "../../../media/img/icons/user-gray.svg";
import carSvg from "../../../media/img/icons/car-gray.svg";
import calendarSvg from "../../../media/img/icons/calendar-gray.svg";
import pinSvg from "../../../media/img/icons/pin-gray.svg";
import cartSvg from "../../../media/img/icons/cart-gray.svg";
import ArrRightSvg from "../../../media/img/icons/array-right-gray.svg";

const icons = {
	user: userSvg,
	car: carSvg,
	calendar: calendarSvg,
	pin: pinSvg,
	cart: cartSvg,
	arrRight: ArrRightSvg,
};

const DetailProp = props => {
	return (
		<div
			className={'o-prop' + (props.hovered ? ' o-prop--hovered':'') + (props.disabled ? ' o-prop--disabled':'') + (props.selected ? ' o-prop--selected':'') + (props.hasError ? ' o-prop--error':'') + (toString(props.errorText).length > 0 ? ' o-prop--hasError':'')}
			onClick={!props.disabled ? props.onClick:null}
		>
			{!!icons[props.iconLeft] && (
				<div className="o-prop__icon"><img src={icons[props.iconLeft]} alt=""/></div>
			)}
			<div className="o-prop__body">{props.children}</div>
			{!!icons[props.iconRight] && (
				<div className="o-prop__icon"><img src={icons[props.iconRight]} alt=""/></div>
			)}
			{toString(props.errorText).length > 0 && (
				<div className="o-prop__error">{props.errorText}</div>
			)}
		</div>
	);
};

DetailProp.propTypes = {
	iconLeft: PropTypes.oneOf(['user', 'car', 'calendar', 'pin', 'cart', 'arrRight', null]),
	iconRight: PropTypes.oneOf(['user', 'car', 'calendar', 'pin', 'cart', 'arrRight', null]),
	children: PropTypes.any,
	hovered: PropTypes.bool,
	disabled: PropTypes.bool,
	selected: PropTypes.bool,
	onClick: PropTypes.any,
	hasError: PropTypes.bool,
	errorText: PropTypes.string,
};

export default DetailProp;