import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
	clearEntities as clearOrders,
	fetchAll,
	filterFieldChange,
	moduleName as ordersModule,
	setLoading
} from "../../ducks/orders";
import {toString} from '../../helpers';

let runSearchTimer = null;

class SearchOrders extends Component {
	static propTypes = {
		// from connect
		filter: PropTypes.object,

		// from connect actions
		fetchAll: PropTypes.func,
		clearOrders: PropTypes.func,
		setLoading: PropTypes.func,
		filterFieldChange: PropTypes.func,
	};

	render(){
		const search = toString(this.props.filter.SEARCH);

		return (
			<div className="header__inner__left">
				<div className="header__search">
					<input value={search} onChange={this.onChange()} placeholder="Найти"/>
					{search.length ? <div className="header__search__clear" onClick={this.setSearch.bind(this, '')}/>:null}
				</div>
			</div>
		);
	}

	runSearch(){
		const {fetchAll} = this.props;
		clearTimeout(runSearchTimer);
		runSearchTimer = setTimeout(() => {
			fetchAll();
		}, 500);
	}

	onChange = () => ev => {
		this.setSearch(ev.target.value);
	};

	setSearch(value) {
		this.props.filterFieldChange('SEARCH', value);
		this.props.setLoading();
		this.props.clearOrders();
		this.runSearch();
	}
}

export default connect(state => ({
	filter: state[ordersModule].filter,
}), {filterFieldChange, fetchAll, clearOrders, setLoading})(SearchOrders);