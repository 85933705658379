import React, {useState} from 'react';
import Popup from "../../common/Popup";
import Select from "../../common/Select";
import BackendApi from "../../../BackendApi";
import {connect} from "react-redux";
import {setBackendError} from "../../../ducks/backendError";

const timeOptions = [{label: '---', value: ''}];
for(let h = 0; h <= 24; ++h)
{
	for(let m = 0; m <= (h < 24 ? 30:0); m = m + 30)
	{
		const time = String(h).padStart(2, '0')+':'+String(m).padStart(2, '0');
		timeOptions.push(
			{label: time, value: time + ':00'}
		);
	}
}

const PopupAddBox = ({selectedDate, carwashId, onClickCancel, onSuccess, setBackendError}) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [values, setValues] = useState({
		from: '',
		to: '',
	});

	const _setValues = (values) => {
		setError(null);
		setValues(values);
	}

	const onClickSave = () => {
		if((values.from && !values.to) || (!values.from && values.to))
		{
			setError('Необходимо выбрать время работы от - до');
			return;
		}

		setLoading(true);
		const request = BackendApi.carwshBoxUp(
			carwashId,
			selectedDate.format('DD.MM.YYYY'),
			values.from,
			values.to
		);

		request.then(response => {
			if(!!response.result)
			{
				onSuccess();
			}
			else if(!!response.error)
			{
				setBackendError(response);
			}

			setLoading(false);
		})
	}

	return (
		<Popup
			title="Добавить бокс"
			subtitle="Выберите время работы"
		>
			<div className="add-box-times">
				<div className="add-box-times__item">
					<Select
						value={timeOptions.filter(option => option.value === values.from)}
						options={timeOptions}
						placeholder="От"
						onChange={(option) => {
							_setValues({...values, from: option.value});
						}}
					/>
				</div>
				<div className="add-box-times__item">&mdash;</div>
				<div className="add-box-times__item">
					<Select
						value={timeOptions.filter(option => option.value === values.to)}
						options={timeOptions}
						placeholder="До"
						onChange={(option) => {
							_setValues({...values, to: option.value});
						}}
					/>
				</div>
			</div>

			{!!error && (
				<p style={{textAlign: 'center', marginTop: 15, color: 'red'}}>{error}</p>
			)}

			<div className="signin-form__button-wrap">
				<button className="signin-form__button" style={{marginBottom: 10}} type="button" onClick={onClickSave} disabled={loading}>{loading ? 'Подождите...':'Добавить'}</button>
				<button className="signin-form__button signin-form__button-cancel" type="button" onClick={onClickCancel} disabled={loading}>Отмена</button>
			</div>
		</Popup>
	);
};

export default connect(null, {setBackendError})(PopupAddBox);
