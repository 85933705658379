import {combineReducers} from 'redux'
//import {routerReducer as router} from 'react-router-redux'
//import {reducer as form} from 'redux-form'
import authReducer, {moduleName as authModule} from '../ducks/auth';
import ordersReducer, {moduleName as ordersModule} from '../ducks/orders';
import orderReducer, {moduleName as orderModule} from '../ducks/order';
import createOrderReducer, {moduleName as createOderModule} from '../ducks/createOrder';
import backendErrorReducer, {moduleName as backendErrorModule} from '../ducks/backendError';
import carCategoriesReducer, {moduleName as carCategoriesModule} from '../ducks/carCategories';
import servicesAndCategoriesReducer, {moduleName as servicesAndCategoriesModule} from '../ducks/servicesAndCategories';

export default combineReducers({
	/*router, *//*form,*/
	[authModule]: authReducer,
	[ordersModule]: ordersReducer,
	[orderModule]: orderReducer,
	[createOderModule]: createOrderReducer,
	[backendErrorModule]: backendErrorReducer,
	[carCategoriesModule]: carCategoriesReducer,
	[servicesAndCategoriesModule]: servicesAndCategoriesReducer,
})