import React from 'react';
import DayPicker from 'react-day-picker';
//import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/ru';

export default class ExampleCalendar extends React.Component {
	constructor(props) {
		super(props);
		this.handleDayClick = this.handleDayClick.bind(this);
		this.state = {
			selectedDay: null,
		};
	}
	handleDayClick(day, { selected }) {
		this.setState({
			selectedDay: selected ? undefined : day,
		});
	}
	render() {
		return (
			<div>
				{this.state.selectedDay && this.state.selectedDay.toLocaleString()}
				<DayPicker
					localeUtils={MomentLocaleUtils}
					locale='ru'
					selectedDays={this.state.selectedDay}
					onDayClick={this.handleDayClick}
					showOutsideDays
				/>
				<p>
					{this.state.selectedDay
						? this.state.selectedDay.toLocaleDateString()
						: 'Please select a day 👻'}
				</p>
			</div>
		);
	}
}