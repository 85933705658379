import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
	clear,
	moduleName as createOrderModule,
	preview,
	save,
	serviceIdsSelector,
	setField
} from '../../ducks/createOrder';
import {push} from "../../history";
import Popup from "../common/Popup";
import {createMoment, displayDuration, toInt, toString} from "../../helpers";
import Field from "./common/Field";
import FieldList from "./common/FieldList";
import DetailProp from "./common/DetailProp";
import DetailPropList from "./common/DetailPropList";
import CarCategoryCardsSelect from "./common/CarCategoryCardsSelect";
import AsidePopupServiceCardsSelect from "./common/AsidePopupServiceCardsSelect";
import ServiceDetailProp from "./common/ServiceDetailProp";
import AsidePopupDateTimeSelect from "./common/AsidePopupDateTimeSelect";
import InputMask from "react-input-mask";

let previewTimer = null;

class CreateOrder extends Component {
	static propTypes = {
		profile: PropTypes.object.isRequired,

		// from connect
		order: PropTypes.object.isRequired,
		selectedServiceIds: PropTypes.array,
		saveLoading: PropTypes.bool,
		saved: PropTypes.bool,

		// from connect actions
		setField: PropTypes.func,
		preview: PropTypes.func,
		save: PropTypes.func,
		clear: PropTypes.func,
	};

	constructor(props){
		super(props);

		this.state = {
			isOpenServiceSelect: false,
			isOpenDateTimeSelect: false,
		}
	}

	componentDidMount(){
		const {order} = this.props;
		const {CARWASHES} = this.props.profile;
		this.props.setField('CARWASH_ID', order.CARWASH_ID ? order.CARWASH_ID:(CARWASHES.slice && CARWASHES[0] ? CARWASHES[0].ID:null));
		this.preview();
	}

	render(){
		const {order, selectedServiceIds, saveLoading} = this.props;

		const hasPrice = parseFloat(order.PAY_PRICE) > 0;

		const dateReserveStart = createMoment(order.DATE_START);
		const dateReserveEnd = createMoment(order.DATE_END);

		return (
			<div>
				<div className="aside-popup-wrap" onClick={this.handleClose}/>
				<div className="aside-popup-group">
					<div className="aside-popup">
						<div className="aside-popup__head">
							<h2>Добавить запись</h2>
							<div className="aside-popup__head__close" onClick={this.handleClose}>Отмена</div>
						</div>
						<div className="aside-popup__body">
							<Field
								className="mb15"
								label="Номер ТС с регионом"
								placeholder="o000oo 000"
								mask="a999aa 999"
								maskFormatChars={{
									'9': '[0-9]',
									'a': '[а-яА-ЯёЁ]'
								}}
								value={toString(order.CAR_NUMBER)}
								onChange={this.handleInputFieldChange('CAR_NUMBER')}
								onBlur={this.handleInputFieldChangeEnd}
							/>

							<CarCategoryCardsSelect
								className="mb25"
								selectedId={parseInt(order.CAR_CATEGORY_ID)}
								onChange={this.setField.bind(this, 'CAR_CATEGORY_ID')}
							/>

							<DetailPropList className="mb25">
								<ServiceDetailProp
									orderItems={order.ITEMS.valueSeq().toArray()}
									nonMessage="Выбрать услуги"
									iconRight="arrRight"
									hovered
									disabled={!parseInt(order.CAR_CATEGORY_ID)}
									selected={this.state.isOpenServiceSelect}
									onClick={!!parseInt(order.CAR_CATEGORY_ID) ? this.handleServiceSelectShow(!this.state.isOpenServiceSelect):null}
								/>
								<DetailProp
									iconLeft="calendar"
									iconRight="arrRight"
									hovered
									disabled={order.serviceIds.size <= 0}
									selected={this.state.isOpenDateTimeSelect}
									hasError={parseInt(order.MORE_TIME_NEED) > 0}
									errorText={parseInt(order.MORE_TIME_NEED) > 0 ? `Не хватает ${order.MORE_TIME_NEED_DISPLAY}`:''}
									onClick={this.handleDateTimeSelectShow(!this.state.isOpenDateTimeSelect)}
								>
									{order.serviceIds.size > 0 && dateReserveStart.isValid() ? (
										<span>
											{dateReserveStart.format('LL')},&nbsp;
											{dateReserveStart.format('HH:mm')}&nbsp;-&nbsp;
											{dateReserveEnd.isValid() ? dateReserveEnd.format('HH:mm'):'...'},&nbsp;
											{parseInt(order.BOX_NUMBER) > 0 && `${order.BOX_NUMBER} бокс`}
										</span>
									):'Выбрать дату и время'}
								</DetailProp>
							</DetailPropList>

							<FieldList>
								<div className="o-field">
									<div className="o-field__label">Продолжительность мойки</div>
									<div className="o-field-duration">
										<InputMask
											className="o-field__input"
											type="text"
											placeholder="__:__"
											mask="99:99"
											value={displayDuration(order.DURATION)}
											onChange={this.handleInputFieldChange('DURATION')}
											onBlur={this.handleInputFieldChangeEnd}
											disabled={!!!order.CUSTOM_DURATION}
										/>
										<div className={'o-field-duration__edit-toggle' + (!!order.CUSTOM_DURATION ? ' on':'')} onClick={this.handleCustomDurationToggle}/>
									</div>
								</div>
							</FieldList>

							<FieldList>
								<Field
									label="Имя"
									placeholder="Имя клиента"
									value={toString(order.CLIENT_NAME)}
									onChange={this.handleInputFieldChange('CLIENT_NAME')}
									onBlur={this.handleInputFieldChangeEnd}
								/>
								<Field
									label="Номер"
									placeholder="+7 (___) ___ _____"
									mask="+7 (999) 999 9999"
									value={toString(order.CLIENT_PHONE)}
									onChange={this.handleInputFieldChange('CLIENT_PHONE')}
									onBlur={this.handleInputFieldChangeEnd}
								/>
							</FieldList>
							<textarea
								className="o-comment-textarea"
								placeholder="+ Добавить комментарий"
								value={toString(order.MANAGER_COMMENT)}
								onChange={this.handleInputFieldChange('MANAGER_COMMENT')}
								onBlur={this.handleInputFieldChangeEnd}
							/>
						</div>
						<div className="aside-popup__foot">
							{hasPrice && (
								<div className="o-summary mb20">
									<div className="o-summary__item">
										<span className="c-dark-black fs19 bold">Итого</span>
										<span className="c-dark-black fs19 bold">{parseFloat(order.PAY_PRICE).toLocaleString()} ₽</span>
									</div>
								</div>
							)}
							<button className="btn btn--yellow w100" disabled={!this.isCanAdd()} onClick={this.handleClickSave}><span>{saveLoading ? 'Подождите...':'Добавить запись'}</span></button>
						</div>
					</div>
					<AsidePopupDateTimeSelect
						isOpen={this.state.isOpenDateTimeSelect}
						handleClose={this.handleDateTimeSelectShow(false)}
						onSelect={this.handleDateStartSelect}
						order={order}
						key={!!order.CUSTOM_DURATION ? order.DURATION:null}
					/>
					<AsidePopupServiceCardsSelect
						carwashId={parseInt(order.CARWASH_ID)}
						carCategoryId={parseInt(order.CAR_CATEGORY_ID)}
						initSelectedIds={selectedServiceIds}
						onSelect={this.setField.bind(this, 'serviceIds')}
						isOpen={this.state.isOpenServiceSelect}
						handleClose={this.handleServiceSelectShow(false)}
					/>
				</div>
				{this.getSuccessPopup()}
			</div>
		)
	}

	getSuccessPopup(){
		const {saved, order} = this.props;
		if(!saved) return null;

		const dateReserveStart = createMoment(order.DATE_START);
		const dateReserveEnd = createMoment(order.DATE_END);

		const subtitle = `${dateReserveStart.format('LL')}, ${dateReserveStart.format('HH:mm')} - ${dateReserveEnd.format('HH:mm')}, ${order.BOX_NUMBER} бокс`;
		return (
			<Popup type="success" title="Запись добавлена" subtitle={subtitle}>
				<div className="signin-form__button-wrap">
					<button className="signin-form__button" type="button" onClick={this.handleClickSuccess}>ОК</button>
				</div>
			</Popup>
		)
	}

	setField(field, value, sendPreview = true) {
		this.props.setField(field, value);
		if(!!sendPreview) this.preview();
	}

	preview(){
		this.props.preview();
	}

	isCanAdd(){
		const {order, saveLoading, saved} = this.props;
		if(saveLoading || saved) return false;
		if(order.CAR_NUMBER === '______ ___' || !toString(order.CAR_NUMBER).length) return false;
		if(!parseInt(order.CAR_CATEGORY_ID)) return false;
		if(!order.serviceIds.size) return false;
		//if(!toString(order.DATE_START).length) return false;
		if(!toString(order.CLIENT_NAME).length) return false;
		if(order.CLIENT_PHONE === '+7 (___) ___ ____' || !toString(order.CLIENT_PHONE).length) return false;
		return true;
	}

	deferPreview(){
		clearTimeout(previewTimer);
		previewTimer = setTimeout(this.preview.bind(this), 500);
	}

	handleInputFieldChange = field => ev =>{
		let value = ev.target.value;

		switch(field)
		{
			case 'DURATION':
				if(value === '__:__') value = '';
				if(value.length > 0 && value.indexOf(':') !== -1)
				{
					let [hours, minutes] = value.split(':').map(item => {
						return toInt(item.replace(/^(_|0)*/g, '').replace(/_$/g, '0'));
					});
					value = (hours * 60 * 60) + (minutes * 60);
				}
				break;
		}

		this.setField(field, value, false);
	};

	handleInputFieldChangeEnd = () =>{
		this.preview();
	};

	handleClose = () => {
		push('/');
	};

	handleServiceSelectShow = show => () => {
		this.setState({
			isOpenServiceSelect: !!show,
			isOpenDateTimeSelect: false,
		})
	};

	handleDateTimeSelectShow = show => () => {
		this.setState({
			isOpenDateTimeSelect: !!show,
			isOpenServiceSelect: false,
		});
	};

	handleCustomDurationToggle = ev => {
		this.setField('CUSTOM_DURATION', !!this.props.order.CUSTOM_DURATION ? 0:1);
	};

	/**
	 * @property {moment.Moment} dateStart
	 * @property {moment.Moment} dateEnd
	 * @property {number, string} boxNumber
	 */
	handleDateStartSelect = (dateStart, dateEnd, boxNumber) => {
		this.setField('DATE_START', dateStart, false);
		this.setField('BOX_NUMBER', boxNumber);
	};

	handleClickSave = ev => {
		this.props.save();
	};

	handleClickSuccess = ev => {
		push(`/order/${this.props.order.ID}/`);
		this.props.clear();
	};
}

export default connect(state => ({
	order: state[createOrderModule].order,
	selectedServiceIds: serviceIdsSelector(state),
	saveLoading: state[createOrderModule].saveLoading,
	saved: state[createOrderModule].saved,
}), {setField, preview, save, clear})(CreateOrder);