import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ServiceCardsSelect from "./ServiceCardsSelect";

class AsidePopupServiceCardsSelect extends Component {
	static propTypes = {
		isOpen: PropTypes.bool,
		onSelect: PropTypes.func,
		handleClose: PropTypes.func,
		initSelectedIds: PropTypes.array,

		// for ServiceCardsSelect
		carwashId: PropTypes.number,
		carCategoryId: PropTypes.number,
	};

	constructor(props){
		super(props);

		this.state = {
			selectedIds: props.initSelectedIds && !!props.initSelectedIds.slice ? props.initSelectedIds.slice:[],
		}
	}

	componentWillReceiveProps(nextProps){
		if(nextProps.initSelectedIds && nextProps.initSelectedIds.slice && nextProps.initSelectedIds !== this.props.initSelectedIds)
		{
			this.setState({
				selectedIds: nextProps.initSelectedIds.slice()
			})
		}
	}

	render(){
		const {isOpen, handleClose, ...rest} = this.props;
		const {selectedIds} = this.state;

		if(!isOpen) return null;

		return (
			<div className="aside-popup aside-popup--narrow aside-popup--light-dark">
				<div className="aside-popup__head">
					<h2>Услуги</h2>
					<div className="aside-popup__head__close" onClick={handleClose}>закрыть</div>
				</div>
				<div className="aside-popup__body">
					<ServiceCardsSelect {...rest} onSelect={this.handleSelectService}/>
				</div>
				<div className="aside-popup__foot">
					<button className="btn btn--yellow w100" onClick={this.handleSelectButtonClick}><span>Выбрать ({selectedIds.length})</span></button>
				</div>
			</div>
		);
	}

	handleSelectService = ids => {
		this.setState({
			selectedIds: ids.slice()
		})
	};

	handleSelectButtonClick = ev => {
		const {onSelect, handleClose} = this.props;
		if(typeof onSelect === 'function') onSelect(this.state.selectedIds);
		if(handleClose) handleClose();
	}
}

export default AsidePopupServiceCardsSelect;