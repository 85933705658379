import React, {Component} from 'react';
import {Router} from 'react-router-dom';
import Root from './components/Root';
import store from './redux';
import {Provider} from 'react-redux';
import history from './history';
import './config';

class App extends Component {
	render(){
		return (
			<Provider store={store}>
				<Router history={history}>
					<Root/>
				</Router>
			</Provider>
		)
	}
}

export default App