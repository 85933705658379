import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
	CALENDAR_VIEW,
	clearEntities as clearOrders,
	fetchAll,
	filterFieldChange,
	filterSet,
	moduleName as ordersModule,
	ordersSelector,
	siteOrdersCountSelector,
	TABLE_VIEW,
	viewTypeChange
} from "../../ducks/orders";
import {setField as setFieldCreateOrder} from '../../ducks/createOrder';
import PropTypes from "prop-types";
import OrdersListTable from './OrdersListTable';
import OrdersListCalendar from './OrdersListCalendar';
import CalendarButton from '../common/CalendarButton';
import Select, {ICON_PIN as SELECT_ICON_PIN} from '../common/Select';
import {createMoment} from "../../helpers";

let refreshTimeOut = null;

class Orders extends Component {
	static propTypes = {
		profile: PropTypes.object.isRequired,

		// from connect
		viewType: PropTypes.string,
		orders: PropTypes.array,
		filter: PropTypes.object,
		loading: PropTypes.bool,
		loaded: PropTypes.bool,
		siteOrdersCount: PropTypes.number,
		carwashBoxNumbers: PropTypes.number,
		boxesTimesWork: PropTypes.objectOf(PropTypes.shape({
			from: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired,
		})),

		// from connect actions
		fetchAll: PropTypes.func,
		viewTypeChange: PropTypes.func,
		filterFieldChange: PropTypes.func,
		clearOrders: PropTypes.func,
		filterSet: PropTypes.func,
		setFieldCreateOrder: PropTypes.func,
	};

	static contextTypes = {
		router: PropTypes.shape({
			history: PropTypes.shape({
				push: PropTypes.func.isRequired,
				replace: PropTypes.func.isRequired,
				createHref: PropTypes.func.isRequired
			}).isRequired
		}).isRequired
	};

	constructor(props){
		super(props);

		this.state = {
			showFromSite: false,
		}
	}

	componentDidMount(){
		const {CARWASHES} = this.props.profile;

		this.props.filterSet({
			CARWASH_ID: CARWASHES.slice && CARWASHES[0] ? CARWASHES[0].ID:null,
			DATE_START_FROM: createMoment(),
			DATE_START_TO: createMoment(),
			DATE_START: createMoment(),
			SEARCH: this.props.filter.SEARCH,
		});
		this.props.clearOrders();
		this.refreshOrders();
	}

	componentWillUnmount(){
		clearTimeout(refreshTimeOut);
	}

	refreshOrders = () => {
		clearTimeout(refreshTimeOut);
		this.props.fetchAll();
		refreshTimeOut = setTimeout(this.refreshOrders, 5000);
	};

	render(){
		const {filter, viewType, siteOrdersCount} = this.props;
		const {showFromSite} = this.state;

		return (
			<div className="container">
				{!filter.SEARCH && (
					<div className="page-bar">
						<h2 className="page-bar__title">Все заявки</h2>
						<div className="page-bar__buttons">
							<div className="page-bar__buttons__left">
								<button onClick={this.setViewType.bind(this, CALENDAR_VIEW)} className={'btn btn--icon-calendar' + (viewType === CALENDAR_VIEW ? ' btn--yellow':'')}/>
								<button onClick={this.setViewType.bind(this, TABLE_VIEW)} className={'btn btn--icon-menu' + (viewType === TABLE_VIEW ? ' btn--yellow':'')}/>
							</div>
							<div className="page-bar__buttons__right">
								{showFromSite && (
									<button className="btn btn--icon-arr-r" onClick={this.handleShowFromSiteChange(false)}>
										<span className="c-dark-black">Все заявки</span>
									</button>
								)}
								<button onClick={this.handleShowFromSiteChange(true)} className={"btn btn--icon-count" + (showFromSite ? ' btn--yellow':'')} data-count={siteOrdersCount}>
									<span className="bold c-dark-black">Записи с сайта</span>
								</button>
							</div>
						</div>
					</div>
				)}
				<div className="mb20">
					{this.renderFilter()}
				</div>
				{this.renderOrders()}
			</div>
		)
	}

	renderOrders(){
		const {viewType, loading, loaded, filter, carwashBoxNumbers, boxesTimesWork} = this.props;
		const orders = this.filteredOrders();

		if(loading && !loaded) return (<div>Загрузка...</div>);

		return (
			<div>
				{viewType === CALENDAR_VIEW ? (
					<OrdersListCalendar
						orders={orders}
						handleCreateOrder={this.handleCreateOrder}
						handleShowOrder={this.handleShowOrder}
						carwashBoxNumbers={carwashBoxNumbers}
						boxesTimesWork={boxesTimesWork}
						selectedDate={filter.DATE_START || createMoment()}
						carwashId={parseInt(filter.CARWASH_ID)}
						isAdmin={this.props.profile && !!this.props.profile['IS_ADMIN']}
						handleRefreshOrders={this.refreshOrders}
					/>
				):(
					<OrdersListTable
						orders={orders}
						handleShowOrder={this.handleShowOrder}
					/>
				)}
			</div>
		)
	}

	renderFilter(){
		const {viewType, filter, profile} = this.props;
		const {CARWASHES} = profile;

		let selectedOptionKey = null;
		const selectOptions = CARWASHES && CARWASHES.slice ? CARWASHES.map((carwash, index) => {
			const option = {label: carwash['ADDRESS'], value: carwash['ID']};
			if(carwash['ID'] === filter.CARWASH_ID) selectedOptionKey = index;
			return option;
		}):[];

		const selectElement = selectOptions.length > 1 ? (
			<Select
				value={selectedOptionKey !== null && selectOptions[selectedOptionKey]}
				options={selectOptions}
				icon={SELECT_ICON_PIN}
				onChange={this.setFilterField.bind(this, 'CARWASH_ID')}
			/>
		):null;

		switch(viewType)
		{
			case CALENDAR_VIEW:
				return (
					<div>
						<CalendarButton
							handleDayChange={this.setFilterField.bind(this, 'DATE_START')}
							key="calendar1"
							selectedDay={filter.DATE_START ? filter.DATE_START.toDate():null}
							buttonExtClassName="mr20"
						/>
						{selectElement}
					</div>
				);

			case TABLE_VIEW:
				return (
					<div>
						<CalendarButton
							handleDayChange={this.setFilterField.bind(this, 'DATE_START_FROM')}
							key="calendar2"
							selectedDay={filter.DATE_START_FROM ? filter.DATE_START_FROM.toDate():null}
						/>&nbsp;&mdash;&nbsp;
						<CalendarButton
							handleDayChange={this.setFilterField.bind(this, 'DATE_START_TO')}
							key="calendar3"
							selectedDay={filter.DATE_START_TO ? filter.DATE_START_TO.toDate():null}
							buttonExtClassName="mr20"
						/>
						{selectElement}
					</div>
				);

			default:
				return null;
		}
	}

	filteredOrders(){
		const {orders} = this.props;
		return this.state.showFromSite ? orders.filter((order) => {
			return order.FROM === 'site';
		}):orders;
	}

	setViewType(type){
		this.props.viewTypeChange(type);
		this.props.clearOrders();
		this.refreshOrders();
	}

	setFilterField(field, value){
		if(typeof value === 'object' && value.hasOwnProperty('value')) value = value.value;
		this.props.filterFieldChange(field, value);
		this.refreshOrders();
	}

	handleShowFromSiteChange = value => ev => {
		this.setState({
			showFromSite: value
		});
	};

	handleShowOrder = id => ev => {
		this.context.router.history.push(`/order/${id}/`);
	};

	handleCreateOrder = (dateStart, boxNumber) => ev => {
		this.props.setFieldCreateOrder('DATE_START', dateStart);
		this.props.setFieldCreateOrder('BOX_NUMBER', boxNumber);
		this.props.setFieldCreateOrder('CARWASH_ID', this.props.filter.CARWASH_ID);
		this.context.router.history.push(`/order/create/`);
	};
}

export default connect(state => ({
	orders: ordersSelector(state),
	filter: state[ordersModule].filter,
	viewType: state[ordersModule].viewType,
	loading: state[ordersModule].loading,
	loaded: state[ordersModule].loaded,
	carwashBoxNumbers: state[ordersModule].carwashBoxNumbers,
	boxesTimesWork: state[ordersModule].boxesTimesWork,
	siteOrdersCount: siteOrdersCountSelector(state),
}), {
	fetchAll,
	viewTypeChange,
	filterFieldChange,
	clearOrders,
	filterSet,
	setFieldCreateOrder
})(Orders)
