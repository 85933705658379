import React from 'react';
import PropTypes from 'prop-types';

const DetailPropList = props => {
	return (
		<div className={"o-prop-group" + (!!props.className ? ` ${props.className}`:'')}>
			{props.children}
		</div>
	);
};

DetailPropList.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
};

export default DetailPropList;