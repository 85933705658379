import React from 'react';
import PropTypes from 'prop-types';

const FieldList = props => {
	return (
		<div className={"o-field-list" + (!!props.className ? ` ${props.className}`:'')}>
			{props.children}
		</div>
	);
};

FieldList.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
};

export default FieldList;