import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createMoment} from '../../helpers';
import {getHMByDate, calculateTimeStatus} from "../../helpers";
import {fromNames, paidNames} from '../../config';
import StatusLables from "./common/StatusLables";
import IconTimeStatus from "./common/IconTimeStatus";

class OrdersListTable extends Component {
	static propTypes = {
		orders: PropTypes.array,
		handleShowOrder: PropTypes.func.isRequired,
	};

	/**
	 * Формирует массив броней по датам
	 * @return {Array}
	 */
	getOrdersByDates() {
		const {orders} = this.props;
		let ordersByDates = [];
		let dateToIndex = {};
		let dateIndexCounter = 0;

		for(let i = 0; i < orders.length; ++i)
		{
			const order = orders[i];

			const dateReserve = createMoment(order.DATE_START);
			const date = dateReserve.format('LL');

			if(dateToIndex[date] === undefined)
			{
				dateToIndex[date] = dateIndexCounter;
				++dateIndexCounter;
			}

			const dateIndex = dateToIndex[date];

			if(!ordersByDates[dateIndex])
			{
				ordersByDates[dateIndex] = {
					date: date,
					orders: []
				};
			}

			ordersByDates[dateIndex].orders.push(order);
		}

		return ordersByDates;
	}

	render(){
		const ordersByDates = this.getOrdersByDates();

		const datesElements = ordersByDates.length ? ordersByDates.map(dateItem => {
			const orders = dateItem.orders;
			const ordersElements = orders.map((order) => {

				const dateReserveStart = createMoment(order.DATE_START);
				const dateReserveEnd = createMoment(order.DATE_END);

				return (
					<div className="o-table__row" key={order.ID} onClick={this.props.handleShowOrder(order.ID)}>
						<div className="o-table__col o-table__col--time">
							<div>{getHMByDate(dateReserveStart)}</div>
							<div>{getHMByDate(dateReserveEnd)}</div>
						</div>
						<div className="o-table__col o-table__col--user">
							<div>{order.CLIENT_NAME}</div>
							<div>{order.CLIENT_PHONE}</div>
						</div>
						<div className="o-table__col o-table__col--car">
							<div>{order.CAR_NUMBER}</div>
							<div>{order.CAR_MARK} {order.CAR_MODEL}</div>
						</div>
						<div className="o-table__col o-table__col--service">
							<div>{order.SERVICES[0] ? order.SERVICES[0]['NAME']:'---'}</div>
							<div>
								{(order.SERVICES.length - 1) > 0 ? '+ ' + (order.SERVICES.length - 1) + " доп услуги":''}
								<span className={'c-gray' + ((order.SERVICES.length - 1) > 0 ? ' ml10':'')}>{order.BOX_NUMBER} бокс</span>
							</div>
						</div>
						<div className="o-table__col o-table__col--price">
							<div className="nowrap">{parseFloat(order.PAY_PRICE).toLocaleString()} ₽</div>
							<div>#{order.ID}</div>
						</div>
						<div className="o-table__col o-table__col--label">
							<StatusLables from={order.FROM} confirmed={order.CONFIRMED} paid={order.PAID}/>
						</div>
						<div className="o-table__col o-table__col--status">
							<IconTimeStatus timeStatus={calculateTimeStatus(dateReserveStart, dateReserveEnd)}/>
						</div>
					</div>
				)
			});

			return (
				<div key={dateItem.date}>
					<h2 className="h2">{dateItem.date}</h2>
					<div className="o-table">
						{ordersElements}
					</div>
				</div>
			);
		}):'Не найдено';

		return (
			<div>
				{datesElements}
			</div>
		)
	}
}

export default OrdersListTable;