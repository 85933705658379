import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setBackendError} from '../../../ducks/backendError';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/ru';
import {createMoment, displayDuration} from "../../../helpers";
import BackendApi from "../../../BackendApi";

let freeReservingCountRequest = 0;

function MonthTabs({date, localeUtils, onChange}){
	let startDate = createMoment(date).subtract(2, "months");

	const handleChange = function handleChange(month, year){
		onChange(new Date(year, month));
	};

	const renderedMonths = [];

	for(let i = 0; i < 3; ++i)
	{
		startDate = startDate.add(1, 'months');
		const selected = date.getMonth() === startDate.month();
		renderedMonths.push((
			<div
				onClick={handleChange.bind(this, startDate.month(), startDate.year())}
				key={i}
				className={"DayPicker-months-tab__item" + (selected ? ' DayPicker-months-tab__item--selected':'')}
			>
				{startDate.format('MMMM')}
			</div>
		))
	}

	return (
		<div className="DayPicker-months-tab">
			{renderedMonths}
		</div>
	);
}

class DateTimeSelect extends React.Component {
	static propTypes = {
		order: PropTypes.object.isRequired,
		onSelect: PropTypes.func,

		// from connect actions
		setBackendError: PropTypes.func
	};

	constructor(props){
		super(props);

		const selectedDay = createMoment(props.order.DATE_START);

		this.state = {
			month: selectedDay.isValid() ? selectedDay.toDate():createMoment().toDate(),
			selectedDay: selectedDay.isValid() ? selectedDay.toDate():null,
			loading: false,
			errorFind: null,
			freeReserving: [],

			selectedDateStart: null,
			selectedDateEnd: null,
			selectedBoxnumber: null,
		};
	}

	componentDidMount(){
		this.loadFreeReserving(createMoment(this.state.selectedDay));
		this.setDateStart(null, null, null);
	}

	render(){
		const {order} = this.props;
		const {selectedDay, month, freeReserving, loading, errorFind} = this.state;

		if(!order) return null;

		return (
			<div className="o-date-time-select">
				<div className="o-date-select">
					<DayPicker
						localeUtils={MomentLocaleUtils}
						locale='ru'
						month={month}
						selectedDays={selectedDay}
						onDayClick={this.handleDayClick}
						showOutsideDays
						captionElement={({date, localeUtils}) => (
							<MonthTabs
								date={date}
								localeUtils={localeUtils}
								onChange={this.handleYearMonthChange}
							/>
						)}
						canChangeMonth={false}
						disabledDays={{before: createMoment().toDate()}}
					/>
				</div>
				<div className="o-time-select">
					<div className="o-time-select__title">
						Выберите время
						<span className="o-time-select__title__time">{displayDuration(order.DURATION)}</span>
					</div>
					{!selectedDay && 'Необходимо выбрать дату'}
					{loading && <div style={{paddingBottom: 10}}>Загрузка...</div>}
					{!!errorFind && <div style={{paddingBottom: 10}}>{errorFind}</div>}
					{freeReserving.slice && freeReserving.length > 0 && freeReserving.map((item, index) => {
						const dateStart = createMoment(item.DATE_START);
						const dateEnd = createMoment(item.DATE_END);
						const isSelect = createMoment(this.state.selectedDateStart).toString() === dateStart.toString();
						return (
							<div
								className={"o-time-select__item" + (isSelect ? ' o-time-select__item--selected':'')}
								key={index}
								onClick={this.handleDateStartChange(dateStart, dateEnd, (item['BOX_NUMBERS'] && !!item['BOX_NUMBERS'].slice ? item['BOX_NUMBERS'][0]:null))}
							>
								{dateStart.format('HH:mm')} — {dateEnd.format('HH:mm')}
							</div>
						)
					})}
				</div>
			</div>
		);
	}

	loadFreeReserving(selectedDay){
		const {order} = this.props;

		++freeReservingCountRequest;
		const requestNumber = freeReservingCountRequest;

		if(!selectedDay.isValid()) return;

		this.setState({
			loading: true,
			errorFind: null,
			freeReserving: [],
		});

		const data = {
			date: selectedDay.format('DD.MM.YYYY'),
			carwash_id: parseInt(order.CARWASH_ID),
			service_id: []
		};
		if(order.ID) data.order_id = order.ID;
		if(order.serviceIds.size) data.service_id = order.serviceIds.toArray();
		if(!!order.CUSTOM_DURATION) data.duration = order.DURATION;

		const request = BackendApi.orderFindFreeReserving(data);

		if(requestNumber === freeReservingCountRequest)
		{
			request.then(response => {
				if(!!response.result)
				{
					this.setState({
						loading: false,
						freeReserving: response.result,
					})
				}
				else if(!!response.error)
				{
					this.setState({
						loading: false,
						errorFind: response.error_description
					})
				}
			});
		}
	}

	/**
	 * @param {moment.Moment} dateStart
	 * @param {moment.Moment} dateEnd
	 * @param {string|number|null} boxNumber
	 */
	setDateStart(dateStart, dateEnd, boxNumber){
		this.setState({
			selectedDateStart: dateStart,
			selectedDateEnd: dateEnd,
			selectedBoxnumber: boxNumber,
		});
		if(typeof this.props.onSelect === "function")
		{
			this.props.onSelect(dateStart, dateEnd, boxNumber);
		}
	}

	handleDateStartChange = (dateStart, dateEnd, boxNumber) => ev => {
		this.setDateStart(dateStart, dateEnd, boxNumber);
	};

	handleDayClick = (selectedDay, modifiers, dayPickerInput) => {
		if(!!modifiers.disabled) return;
		this.setState({
			selectedDay
		});
		this.setDateStart(null, null, null);
		this.loadFreeReserving(createMoment(selectedDay));
	};

	handleYearMonthChange = month => {
		this.setState({month});
	};
}

export default connect(null, {setBackendError})(DateTimeSelect);