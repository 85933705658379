import React from 'react';
import PropTypes from 'prop-types';
import {toString} from "../../helpers";

const typeToClassName = {
	success: 'popup--success',
	error: 'popup--error',
};

const Popup = props => {
	return (
		<div className="popup-wrap">
			<div className={'popup' + (!!typeToClassName[props.type] ? ` ${typeToClassName[props.type]}`:'')}>
				{props.type === 'success' && (
					<div className="acenter">
						<span className="popup__ok"/>
					</div>
				)}
				{props.type === 'error' && (
					<div className="acenter">
						<span className="popup__error"/>
					</div>
				)}
				{toString(props.title).length > 0 && (
					<h2 className="popup__title">{props.title}</h2>
				)}
				{toString(props.subtitle).length > 0 && (
					<div className="popup__enter-in">{props.subtitle}</div>
				)}
				{props.children}
			</div>
		</div>
	);
};

Popup.propTypes = {
	type: PropTypes.oneOf(['success', 'error', null]),
	title: PropTypes.string,
	subtitle: PropTypes.string,
	children: PropTypes.any
};

export default Popup;