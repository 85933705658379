import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const Field = props => {
	const {label, type, placeholder, value, onChange, onBlur, mask, maskChar, maskFormatChars, disabled, className} = props;

	return (
		<div className={"o-field" + (!!className ? ` ${className}`:'') + (!!disabled ? ' o-field--disabled':'')}>
			{!!label && label.length > 0 && <div className="o-field__label">{label}</div>}
			{!!mask ? (
				<InputMask
					className="o-field__input"
					type={!!type && type.length > 0 ? type:'text'}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					mask={mask}
					maskChar={maskChar}
					formatChars={maskFormatChars}
					disabled={disabled}
				/>
			):(
				<input
					className="o-field__input"
					type={!!type && type.length > 0 ? type:'text'}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					disabled={disabled}
				/>
			)}
		</div>
	);
};

Field.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool,

	mask: PropTypes.string,
	maskChar: PropTypes.string,
	maskFormatChars: PropTypes.object,
};

export default Field;