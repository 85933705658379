import {saga as authSaga} from '../ducks/auth';
import {saga as ordersSaga} from '../ducks/orders';
import {saga as orderSaga} from '../ducks/order';
import {saga as createOrderSaga} from '../ducks/createOrder';
import {saga as carCategoriesSaga} from '../ducks/carCategories';
import {saga as servicesAndCategoriesSaga} from '../ducks/servicesAndCategories';
import {all} from 'redux-saga/effects';

export default function * rootSaga() {
    yield all([
        authSaga(),
		ordersSaga(),
		orderSaga(),
		createOrderSaga(),
		carCategoriesSaga(),
		servicesAndCategoriesSaga(),
    ])
}